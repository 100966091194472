@mixin dotx5() {
	background:url(/static/portal/img/dotx5.png) 0 bottom repeat-x;
	background-size:5px 1px;
}
.showview{
	&::after {
		content: '';
		display:block;
		clear:both;
	}
	.fl{
		width:42.10526315789474%;
		// padding-right:9px;
		@include breakpoint($sm){
			width:100%;
			padding-right:0;
		}
	}
	.fr{
		width:56.03715170278638%;
		// padding-left:9px;
		@include breakpoint($sm){
			width:100%;
			padding-left:0;
		}
	}
	.leftpic{
		img{width:100%;vertical-align:top;}
	}
	.rightTop{
		border:1px solid #d7d8de;
		padding:27px 30px;
		h1{
			font-size: 27px;
			font-family: "NanumSquare";
			color: rgb(75, 87, 159);
			font-weight: bold;
			line-height: 1;
			padding-bottom:14px;
			background:url(/static/portal/img/dotx5.png) 0 bottom repeat-x;
			background-size:5px 1px;
			margin-bottom:10px;
		}
		.set{
			dl{margin-bottom:8px;}
			dt{width:70px;float:left;
				font-weight:bold;
			}
			dd{margin-left:70px;}
		}
		.mb{
			// 체험프로그램 > 예매하기 상단 여백
			margin-bottom:32px;
		}
		a{margin-left:0;margin-right:0;}
	}
	.promotion{
		position:relative;
		.thumbs{
			overflow:hidden;
			&::after {
				content: '';
				padding-top:45.85635359116022%;
			}
			.set{height:100%;}
		}
		.promationCtrl{
			position:absolute;
			top:11px;
			right:13px;
			float:right;
			button{
				float:left;
				background: url(/static/portal/img/promotionrightarr.png) center center no-repeat;
				background-size:11px;
				width:39px;
				height:39px;
				border:1px solid #fff;
			}
			.left{
				background-image:url(/static/portal/img/promotionleftarr@3x.png);
			}
			.right{
				margin-left:-1px;
			}
			
		}
		h2{
			background-color:#4b579f;
			font-size: 27px;
			font-family: "NanumSquare";
			color: rgb(255, 255, 255);
			line-height: 1;
			padding:19px 0 16px 15px;
			font-weight:normal;
			margin:15px 0 0 0;
		}
		.pic{
			a{display:block;}
			img{
				width:100%;
			}
		}
	}
}
[class^=c-]{
	display:inline-block;
	border-radius:10px;
	width:15px;
	height:15px;
	line-height:15px;
	color:#fff;
	text-align:center;
	font-size:10px;
	margin-right:4px;
	vertical-align:middle;
	font-family:'Verdana';
	&.c-v{background-color:#cd9659;}
	&.c-r{background-color:#b93a37;}
	&.c-s{background-color:#606798;}
	&.c-a{background-color:#cb0000;}
}
.bdViewNav{
	border:2px solid $bdTopColor;
	border-width:2px 0;
	dl{
		overflow:hidden;
	}
	dl+dl{border-top:1px solid #bfbfbf;}
	dt{
		float:left;
		width:57px;
		position:relative;
		font-weight:bold;
		text-align:center;
		padding:10px 0;
		&::after {
			content: '';
			height:12px;
			right:0;
			top:12px;
			border-right:1px solid #dddddd;
			position:absolute;
		}
	}
	dd{
		margin-left:57px;
		padding-left:24px;
	}
	a{
		display:block;
		padding:10px 0;
	}
}
.showlist{
	margin-top:-5px;
	margin-left:-5px;
	margin-right:-5px;
	.noshow{
		text-align:center;
		padding:20px 0;
		font-size: 24px;
		font-family: "NanumSquare";
		color: #4b579f;
		line-height: 1.2;
	}
	ul{
		overflow:hidden;
	}
	li{
		padding:5px;
		width:50%;
		float:left;
		&:nth-child(odd){
			clear:left;
		}
		@include breakpoint($sm){
			width:100%;
			float:none;
		}
		>div{
			border:1px solid #d7d8de;
			padding:9px;
		}
		&.end .leftpic a:before{
				display:none;
				content: '';
				background-color:rgba(0,0,0,0.6);
				position:absolute;
				z-index:1;
				left:0;
				top:0;
				width:100%;
				height:100%;
		}
		// 종료멘트 활성화
		&:hover .leftpic a:before,
		&:focus .leftpic a:before{display:block; }
		&:hover .leftpic span.enddeco,
		&:focus .leftpic span.enddeco{display:block;}
		.leftpic{
			position:relative;
			overflow:hidden;
			&::after {
				content: '';
				padding-top:143.5233160621762%;
				display:block;
				width:100%;
			}
			a{
				position:absolute;
				width:100%;
				left:0;top:0;
				img{vertical-align:top;}
			}
			span.enddeco{
				display:none;
				position:absolute;
				z-index:2;
				left:50%;
				top:50%;
				color:#fff;
				text-align:center;
				border:2px solid #fff;
				padding:10px 20px;
				margin-left:-61px;
				margin-top:-32px;
				font-size:15px;
				font-weight:bold;
				}
		}
	}
	.rightTop{
		border:none;
		padding:0;
		padding-top:5px;
		padding-left:8px;
		min-height:260px;
		[class^=cate-]{
			font-size:11px;
			color:#fff;
			background-color:#f05291;
			padding:1px 4px;
			display:inline-block;
			border-radius:2px;
			&.cate-schedule{
				background:#4da788 url(/static/portal/img/ico-schedlue@3x.png) 5px center no-repeat;
				background-size:10px;
				padding-left:19px;
			}
			&.cate-end{
				background-color:#666;
				padding:1px 10px;
			}
		}
		h2{
			margin:0;
			font-size: 24px;
			font-family: "NanumSquare";
			color: rgb(75, 87, 159);
			line-height: 1.2;
			margin:3px 0 5px;
			min-height:56px;
			overflow:hidden;
		}
		.decoText{
			padding:0;margin:0;
			min-height:58px;
			overflow:hidden;
			margin-bottom:10px;
			@include breakpoint($sm){
				margin-bottom:16px;
				line-height:1.3;
				height:auto;
				min-height:0;
			}
		}
		.set{
			@include dotx5;
			background-position:0 0;
			padding-top:17px;
			padding-bottom:8px;
			dt{width:47px;}
			dd{margin-left:47px;}
		}
	}
}