.promotionlist{
	.row{
		margin-bottom:12px;
		>div{
			@include breakpoint($sm){
				margin-bottom:12px;
			}
			a{
				display:block;
				border:1px solid #d7d8de;
				position:relative;
				&:hover,
				&:focus{
					border-color:#a1a7cd;
					.end{
						display:block;
					}
				}
				.end{
					display:none;
					content: '';
					background-color:rgba(0,0,0,0.6);
					position:absolute;
					z-index:1;
					left:0;
					top:0;
					width:100%;
					height:100%;
					text-align:center;
					span{
						margin-top:50%;
						position:relative;
						top:-32px;
						display:inline-block;
						z-index:2;
						color:#fff;
						text-align:center;
						border:2px solid #fff;
						padding:10px 20px;
						font-size:15px;
						font-weight:bold;
					}
				}
				span.img{
					position:relative;
					overflow:hidden;
					display:block;
					&::after {
						content: '';
						display:block;
						// padding-top:77.95527156549521%;
						padding-top: 45.85635359116022%;
					}
					img{
						position:absolute;
						top:0;
						left:0;
						width:100%;
					}
				}
				span.tset{
					span{display:block;}
					text-align:center;
					.t{
							font-size: 18px;
							font-family: "NanumSquare";
							color: rgb(75, 87, 159);
							font-weight: bold;
							line-height: 1.2;
							padding:11px 0 3px;
							text-overflow:ellipsis;
							overflow:hidden;
							white-space:nowrap;
					}
					.d{
							font-size: 14px;
							color: rgb(51, 51, 51);
							line-height: 1.2;
							padding-bottom:20px;
					}
				}
			}
		}
	}
}