@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
		@if function-exists(image-url) {
				@return image-url($url);
		}
		@else {
				@return url($slick-loader-path + $url);
		}
}

@function slick-font-url($url) {
		@if function-exists(font-url) {
				@return font-url($url);
		}
		@else {
				@return url($slick-font-path + $url);
		}
}

/* Slider */

.slick-list {
		.slick-loading & {
				// background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
		}
}

/* Icons */
@if $slick-font-family == "slick" {
		@font-face {
				font-family: "slick";
				src: slick-font-url("slick.eot");
				src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
				font-weight: normal;
				font-style: normal;
		}
}

/* Arrows */

.slick-prev,
.slick-next {
		position: absolute;
		display: block;
		height: 20px;
		width: 20px;
		line-height: 0px;
		font-size: 0px;
		cursor: pointer;
		background: transparent;
		color: transparent;
		top: 50%;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		padding: 0;
		border: none;
		border:5px solid #000;
		outline: none;
		&:focus {
				background: transparent;
				color: transparent;
				outline:1px dotted
				// &:before {
				//     opacity: $slick-opacity-on-hover;
				// }
		}
		&.slick-disabled:before {
				opacity: $slick-opacity-not-active;
		}
		// &:before {
		//     font-family: $slick-font-family;
		//     font-size: 20px;
		//     line-height: 1;
		//     color: $slick-arrow-color;
		//     opacity: $slick-opacity-default;
		//     -webkit-font-smoothing: antialiased;
		//     -moz-osx-font-smoothing: grayscale;
		// }
}

.slick-prev {
		left: -25px;
}

.slick-next {
		right: -25px;
}

/* Dots */

.slick-dotted.slick-slider {
		margin-bottom: 30px;
}

.slick-dots{
		position: absolute;
		// border:1px solid #000;
		bottom: -25px;
		list-style: none;
		display: block;
		text-align: center;
		padding: 0;
		margin: 0;
		width: 100%;
		.paging2 &{
			bottom:15px;
		}
		li {
				position: relative;
				// display: inline-block;
				height: 20px;
				// min-width: 20px;
				// margin: 0 5px;
				padding: 0;
				float:left;
				padding-right:3px;
				cursor: pointer;
				.paging2 &{
					width:22px;
					padding:0;
					float:none;
					display:inline-block;
					height:auto;
				}
				button {
						background: transparent;
						display: block;
						height: 20px;
						// min-width: 20px;
						// outline: none;
						line-height: 0px;
						font-size: 0px;
						color: transparent;
						padding: 0;
						cursor: pointer;
						&:hover, &:focus {
								// outline: none;
								&:before {
										opacity: $slick-opacity-on-hover;
										
								}
						}
						&:before {
								-webkit-box-sizing: border-box;
									 -moz-box-sizing: border-box;
												box-sizing: border-box;
								content: '';
								border:2px solid #b6b6b6;
								background-color:#fff;
								width:10px;
								height:10px;
								border-radius:10px;
								display:block;
								-webkit-transition: all .5s;
									 -moz-transition: all .5s;
										-ms-transition: all .5s;
										 -o-transition: all .5s;
												transition: all .5s;
							.paging2 &{
								width:13px;
								height:13px;
								background-color:#6e6e6e;
								border:none;

							}
						}
				}
				&.slick-active button:before {
						background-color:#393b3c;
						border-color:#393b3c;
						width:30px;
						// opacity: $slick-opacity-default;
						.paging2 &{
								width:13px;
								height:13px;
								background-color:#5b65a5;
								border:none;
						}
				}
		}
}
