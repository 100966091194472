.loginSet{
	
	
	.topset{
		margin-top:40px;
		background-color:#eff2f3;
		padding:47px 0;
		@include breakpoint($sm){
			margin-top:20px;
			padding:35px 0;
		}
	}
	
	form{

		max-width:370px;
		margin: 0 auto;
		position:relative;
		@include breakpoint($sm){
			padding:0 10px;
		}
		span{
			display:block;
			padding:0 94px 0 76px;
			margin-bottom:3px;
			position:relative;
			label{
				@include bu4;
				position:absolute;
				left:0;
				top:10px;
				line-height:1;
			}
			input{
				width:100%;
				height:36px;
			}
		}
		>input{
			position:absolute;
			right:0;
			top:-2px;
			width:86px;
			height:77px;
			background:#4b579f url(/static/portal/img/Power.png) center 19px no-repeat;
			@include breakpoint($sm){
				background:#4b579f url(/static/portal/img/Power@3x.png) center 19px no-repeat;
				background-size:14px;
				
			}
			font-size:14px;
			color:#fff;
			border-radius:5px;
			padding-top:24px;
			line-height:1;
			border:none;
			@include breakpoint($sm){
				right:10px;
			}
		}
	}
	.moreLink{
		width:210px*3;
		margin: 0 auto;
		margin-top:45px;
		@include breakpoint($sm){
			width:auto;
			margin-top:20px;
		}
		>div.row{
			>div>div{

				padding-left:33px;
				border-left:1px solid #d5d5d5;
				@include breakpoint($sm){
					border-left:none;
					border-bottom:1px solid #d5d5d5;
					padding:9px 28px;
					overflow:hidden;
				}
			}
			>div:first-child>div{
					border-left:none;
				}
		}
		p{
			font-size: 18px;
			color: rgb(167, 118, 64);
			font-weight: bold;
			line-height: 1.2;
			margin-bottom:8px;
			@include breakpoint($sm){
				float:left;
				font-size:14px;
			}
		}
		a{
			@include breakpoint($sm){
				float:right;
				margin-top:14px;

			}
		}
	
	}
}