$fileurl : '/static/portal/img/file.png';
.file-etc {
	display:inline-block;
  background-image: url($fileurl);
  background-position: 0px 0px;
  width: 26px;
  height: 25px;
}
.file-hwp {
	display:inline-block;
  background-image: url($fileurl);
  background-position: -36px 0px;
  width: 26px;
  height: 25px;
}
.file-img {
	display:inline-block;
  background-image: url($fileurl);
  background-position: 0px -35px;
  width: 26px;
  height: 25px;
}
.file-pdf {
	display:inline-block;
  background-image: url($fileurl);
  background-position: -36px -35px;
  width: 26px;
  height: 25px;
}
.file-ppt {
	display:inline-block;
  background-image: url($fileurl);
  background-position: -72px 0px;
  width: 26px;
  height: 25px;
}
.file-word {
	display:inline-block;
  background-image: url($fileurl);
  background-position: -72px -35px;
  width: 26px;
  height: 25px;
}
.file-xlx {
	display:inline-block;
  background-image: url($fileurl);
  background-position: 0px -70px;
  width: 26px;
  height: 25px;
}