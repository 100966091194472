[class^=b-]{
	display:inline-block;
	font-size:14px;
	color:#fff;
	background-color:#666;
	margin-left:5px;
	text-align:center;
	border-radius:3px;
	height:27px;
	line-height:27px;
	vertical-align:middle;
	padding:0 7px;
	&.md{
		min-width:120px;
	}

	&.lg{
		border-radius:5px;
		background-color:#4b579f;
		min-width:200px;
		height:40px;
		line-height:40px;
		font-size:14px;
		font-weight:bold;
		@include breakpoint($sm){
			min-width:inherit;
			padding-left:10px;
			padding-right:30px;
		}
		&::before {
			content: '';
			display:inline-block;
			height:40px;
			width:30px;
			background-position:0 center;
			background-repeat:no-repeat;
			vertical-align:middle;
		}
		&.b-home{
			background-color:#333;
			&::before {
				background-image:url(/static/portal/img/b-ico-home@3x.png);
				background-size:15px;
			}
		}
		&.b-edit::before {
			background-image:url(/static/portal/img/b-ico-edit@3x.png);
			background-size:23px;
		}
		&.b-memberout::before {
			background-image:url(/static/portal/img/ico-memberout@3x.png);
			background-size:19px;
		}
	}
}
[class^=b-]:hover,
[class^=b-]:focus{
}
// blue button
.b-del,
.b-list,
.b-cancel,
.b-write,
.b-blue,
.b-joinok,
.b-join{
	border-radius:5px;
	background-color:#4b579f;
	min-width:200px;
	height:40px;
	line-height:40px;
	font-size:14px;
	font-weight:bold;
}
// 아이디체크
.b-idchk,
.b-post{
	background-color:#4b579f;
}
.b-joinok::before {
	content: '';
	display:inline-block;
	font:normal normal normal 14px/1 FontAwesome;content:"\f00c";color:#fff;
	margin-right:20px;
}
.b-pw,
.b-passwordfind,
.b-login,
.b-gomain{
	  font-size: 14px;
	  font-family: "Malgun Gothic";
	  color: rgb(255, 255, 255);
	  font-weight: bold;
	  text-transform: uppercase;
	  line-height: 1;
	background:#4b579f url(/static/portal/img/btnset.png) 0 0 no-repeat;
	height:40px;
	border-radius:5px;
	padding:0 28px 0 47px;
	@include breakpoint($sm){
		padding:0 18px 0 42px;
	}
	display:inline-block;
	line-height:40px;
	margin:0 4px 0 0;
}
.b-login{
	background-position:0 0;
}
.b-gomain{
	background-position:0 -40px;
	background-color:#333;
}
.b-passwordfind{
	background-position:0 -80px;
	background-color:#333;
}
.b-pw{
	background-position:0 -80px;
	background-color:#4b579f;
}
[class^=b-mini]{
	border-radius:3px;
	font-size:13px;
	height:27px;
	line-height:25px;
	padding:0 12px 0 7px;
	background:none;
	&::before {
		content: '';
		display:inline-block;
		width:22px;
		height:16px;
		vertical-align:middle;
		background:url(/static/portal/img/Calendar@3x.png) 0 center no-repeat;
		background-size:auto 16px;
		margin-top:-3px;
	}
	&.none::before{
		display:none;
	}
	background-color:#4b579f;
	color:#fff;
}


.b-mini-app{
	&:before {
	}
}
@mixin blackbtn() {
	border:1px solid #333333;
	background-color:#fff;
	color:#333;
}
.b-mini-help{
	@include blackbtn;
	&:before {
		background-image:url(/static/portal/img/Compose@3x.png);
	}
}
.b-mini-list{
	&:before{
		background-image:url(/static/portal/img/b-mini-list@3x.png);
		background-size:12px 10px;
	}
}
.b-mini-black{
	background-color:#333;
	padding-left:0;padding-right:0;
	width:100px;
	@include breakpoint($sm){
		width:80px;
	}
	&:before{display:none;}
}
.b-mini-view{
	@include blackbtn;
	&:before{
		background-image:url(/static/portal/img/btn-mini-view@3x.png);
		background-size:16px 16px;
	}
}
.b-write{
	width:auto;
	padding-right:42px;
	padding-left:25px;
	min-width:inherit;
	&::before {
		content: '';
		display:inline-block;
		margin-right:16px;
		background: url(/static/portal/img/btn-write.png) 0 0 no-repeat;
		@include breakpoint($sm){
			background: url(/static/portal/img/btn-write@3x.png) 0 0 no-repeat;
			background-size:17px;
		}
		width:17px;
		height:13px;
		vertical-align:middle;
	}
}
.b-cancel{
	background-color:#333;
	min-width:120px;
}
.b-del,
.b-list{
	min-width:120px;
	&:before{
		content: '';
		display:inline-block;
		width:22px;
		height:16px;
		vertical-align:middle;
		background-image:url(/static/portal/img/b-mini-list@3x.png);
		background-repeat:no-repeat;
		background-size:12px 10px;
		margin-top:2px;
	}
}
.b-del{
	background-color:#666;
	&:before {
		content: '';
		height:17px;
		margin-top:-2px;
		background-image:url(/static/portal/img/ico-del.png);
		@include breakpoint($sm){
			background-image:url(/static/portal/img/ico-del@3x.png);
		}
		background-size:15px auto;
	}
}
