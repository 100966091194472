// @import url(/static/jslibrary/Swiper-3.4.2/css/swiper.min.css);
@import "./static/jslibrary/slick/slick.scss";
@import "./static/jslibrary/slick/slick-theme.scss";
button{
		background-color:transparent;
	}
//공연사진
.photoset{
	position:relative;
	overflow:hidden;
	&::after {content: ''; display:block;
		padding-top:58.65979381443299%;
	}
	&.type2::after {
		padding-top:51.75257731958763%;
	}
	.slick-slide{
		i{
			display:block;
			width:100%;
			padding-top:58.65979381443299%;
		}
		img{
			position:absolute;
			left:0;
			top:0;
			width:100%;
		}
	}
	.slick-prev,.slick-next{
		width:50px;
		height:55px;
		z-index:999;
		border:none;
		@include breakpoint($sm){
			width:20px;
			height:20px;
			background-size:auto 20px !important;
		}
	}

	.slick-prev{
		left:0;
		background: url(/static/portal/img/photosetLeft.png) 0 0 no-repeat;
	}
	.slick-next{
		right:0;
		background:url(/static/portal/img/photosetRight.png) 0 0 no-repeat;
	}
	.area,
	.qqq{position:absolute; width:100%; top:0; left:0; }
	.ctrl{
		button{
			position:absolute;
			top:50%;
			border:1px solid #fff;
			z-index:300;
			width:50px;
			height:50px;
			&.left{left:0;}
			&.right{right:0;}
		}
	}
	.lSSlideOuter {overflow:hidden;}
	.photoSetList{
		background-color:#f0eff2;
		.imgarea{
			position:absolute;
			left:0;top:0;
			width:100%;
			height:100%;
			text-align:center;
			// display:flex;
			// .i{align-items:center;}
			img{
				max-height: 100%;
				max-width: 100%;
				// position:static;
				position: absolute;
				// display:block;
				margin:0 auto; /* center */
				left:0;        /* center */
				right:0;       /* center */
				&.width100p{
					height:100%;
					width:auto;
				}
				&.height100p{
					width:100%;
					height:auto;
				}
			}
		}

		img{width:100%;}
	}
}
.galleryThumbs{
	margin-top:20px !important;
	// >*{float:left;}
	button{
		display:block;
		margin-right:10px;
		width:100%;
		width:150px;
		background:{
			repeat:no-repeat;
		};
		height:90px;
		-webkit-background-size:100%;
		background-size:100%;
	}
}
.swiper-container {
	width:100%;
	.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
			position:relative;
	}
	.swiper-slide i{
		display:block;
		padding-top:58.65979381443299%;
		width:100%;
		position:relative;
	}
	.swiper-slide img{
		position:absolute;
		width:100%;
		top:0;
		left:0;
	}
}


// 공연정보 상세 > 공연사진
.thumbs{position:relative;overflow:hidden;}
.thumbs .set{position:absolute;top:0;left:0;width:100%;}
.thumbs::after {
	content: '';
	display:block;
	padding-top:90px;
}
.gallery-thumbs {
	height: 90px;
	box-sizing: border-box;
	padding: 10px 0;
	.swiper-slide {
		height: 100%;
		opacity: 1;
	}
	.swiper-slide-active {
		opacity: 1;
	}
}


.swiper-promotion {
	height: 100%;
	box-sizing: border-box;
	// padding: 10px 0;
	.swiper-slide {
		height: 100%;
	}
	.swiper-slide-active {
		opacity: 1;
	}
}


//
.swiperType2{
	margin-bottom:12px;
	@extend .photoset;
	&::after,
	.slide{
		i{

		}
		i{
			padding-top:55.77319587628866%;
		}
	}
	.swiper-pagination{
		text-align:center;
		position:relative;
		z-index:999;
		top:-32px;
		.swiper-pagination-bullet {
				width: 13px;
				height: 13px;
				display: inline-block;
				border-radius:13px;
				background: #6e6e6e;
				opacity: 1;
				margin:0 4px 0 5px;
				&.swiper-pagination-white  {
						background: #fff;
				}
		}
		.swiper-pagination-bullet-active {
				background: #5b65a5;
				&.swiper-pagination-white  {
						background: #fff;
				}
				&.swiper-pagination-black  {
						background: #000;
				}
		}
	}
}


.galleryThumbs2{
	overflow:hidden;
	margin-top:14px;
	@include breakpoint($sm){
		margin-top:5px;
	}
	>div{
			margin:0 -8px 0 -8px;
		@include breakpoint($sm){
			margin:0 -4px;
		}
		>div{
			float:left;
			width:20%;
			padding:0 8px 0 8px;
			@include breakpoint($sm){
				padding:0 4px;
			}
			button{
				// height:119px;
				display:block;
				width:100%;
				background-repeat:no-repeat;
				background-size:100%;
				&::after {
					content: '';
					width:100%;
					display:block;
					height:0;
					padding-top:52%;
				}
			}
		}
	}
}