.contentTopDeco,
.newslettertop{
	position:relative;
	overflow:hidden;
	padding-left:120px;
	background:#fff url(/static/portal/img/HKPU_03_02_01_bg.png) 0 0 no-repeat;
	padding-top:12px;
	margin-bottom:13px;
	min-height:100px;
	&.HKPU_03_04{
		background-image:url(/static/portal/img/HKPU_03_04_bg.png);
		.space{
			display:inline-block;
			padding-left:104px;
			@include breakpoint($sm){
				padding-left:0;
			}
		}
	}
	&.HKPU_03_05_01{
		padding-left:143px;
		background-image:url(/static/portal/img/HKPU_03_05_01_bg.png);
	}
	&.nobg{
		padding:0;
		background-image:none;
		height:auto;
		.visualtxt1{margin:0;}
	}
	@include breakpoint($sm){
		padding:0;
		height:auto;
		background-image:none;
	}
	.left{
		float:left;
		.visualtxt1{margin-bottom:5px;}
		p+p{font-size:14px;
			em{
				font-weight:bold;
				color:$pointcolor;
			}
		}
		p strong{
			font-weight:bold;
			color:$pointcolor;
		}
		@include breakpoint($sm){
			display:none;
		}
	}
	a{position:absolute;
		bottom:0;
		right:0;
		@include breakpoint(max-width 1130px){
			position:static;
			clear:both;
		}
		@include breakpoint($sm){
			float:none;
			position:static;
			width:100%;
			display:block;
			margin:0;
		}
	}
}
.newsletterData{overflow-x:auto;}
.HKPU_08_03{
	height:auto;
	padding-left:150px;
	background-image:url(/static/portal/img/HKPU_08_03_bg.png);
	@include breakpoint($sm){
		padding-left:0;
		background-size:auto 86px;
		.visualtxt1{
			padding-left:100px;
			display:block;
			min-height:86px;
			padding-top:5px;
			br{display:none;}
		}
	}
	.stitle{
		font-size:18px;
		text-indent:-39px;
		padding-left:39px;
		font-weight:bold;
		em{
			font-weight:bold;
			color:#fff;
			background-color:#4b579f;
			display:inline-block;
			width:28px;
			text-align:center;
			padding:5px 0;
			line-height:1;
			text-indent:0;
			font-size:13px;
		}
		&+p{
			margin-left:39px;
			padding:12px 0;
			@include breakpoint($sm){
				padding:5px 0;
			}
		}
	}

}
.b-newsletter {
	&::before {
		content: '';
		width:25px;
		height:16px;
		background-image: url(/static/portal/img/btn-ico-newsletter@3x.png);
		background-size:25px 16px;
		background-repeat:no-repeat;
		display:inline-block;
		margin-right:9px;
		vertical-align:middle;
	}
}
.newsletterlist{
	.row {
		margin-bottom: 12px;
		> div {
			@include breakpoint($sm) {
				margin-bottom: 12px;
			}
			a {
				display: block;
				border: 1px solid #d7d8de;
				position: relative;
				&:hover,
				&:focus {
					border-color: #a1a7cd;
				}
				span.img {
					position: relative;
					overflow: hidden;
					display: block;
					&::after {
						content: '';
						display: block;
						padding-top: 50.47923322683706%;
					}
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}
				}
				.textSet {
					display: block;
					font-size: 18px;
					font-weight: bold;
					line-height: 1.333;
					text-align: center;
					padding:10px 0;
					height:66px;
					@include breakpoint($sm){
						height:auto;
					}
					span {
						display: block;
					}
				}
			}
		}
	}
}
.newsletterapp{
	background:#fff url(/static/portal/img/newsletterapp-bg@3x.png) 0 0 no-repeat;
	background-size:86px;
	padding-left:100px;
	padding-top:12px;
	min-height:78px;
	margin-bottom:18px;
	p.t{
		  font-size: 24px;
		  font-family: "NanumSquare";
		  color: rgb(75, 87, 159);
		  font-weight: bold;
		  line-height: 1.2;
		  margin-bottom:12px;
	}
}
.promotionMovieList{
	@extend .newsletterlist;
	.row {
		> div{
			a {
				span.img {
					&::after {
						padding-top:55.91054313099042%;
					}
				}
				.textSet{
					overflow:hidden;
					padding:0;
					margin:10px 5px;
					height:48px;
				}
			}
		}
	}
}
.gbox{
	background-color:#f0eff2;
	padding:27px 18px 24px 18px;
	border-radius:5px;
	@include breakpoint($sm){
		padding:15px 11px;
	}
	>dt{float:left;margin-right:10px;margin-top:7px;}
}