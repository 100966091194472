/*view*/
.view{
	border-top:2px solid $bdTopColor;
	border-bottom:1px solid $bdColor;
	margin-bottom:30px;
	&+.dbdata{
		margin-top:-30px;
	}
	label+label{margin-left:10px;}
	@include breakpoint($sm){}
}
.view .subject{
	// font-size:19px;color:#000000;padding:8px 18px;margin:0;border-bottom:1px solid $bdTopColor;
	.title{
		font-size: 18px;
		color: rgb(51, 51, 51);
		font-weight: bold;
		line-height: 1.2;
		float:left;
		margin:0;
	}
	.d{
		float:right;
		width:200px;
		text-align:right;
		  font-size: 14px;
		  color: rgb(51, 51, 51);
	}
	padding:12px 0;
	overflow:hidden;
	border-bottom:1px solid #bfbfbf;

}
.view .subject+dl>*{border-top:none;}
.view>dl{display:table;width:100%;border-collapse:collapse;table-layout:fixed;}
.view>dl>dt,
.view>dl>dd{display:table-cell;padding:6px 0;vertical-align:top;border-left:1px solid $bdColor;border-top:1px solid $bdColor;}
.view>dl>dt label{}
.view>dl:first-child>*{border-top:none;}
.view>dl>dt{
	width:15%;
	background-color:$viewheadBgcolor;
	color:$viewheadcolor;
	vertical-align:middle;
	text-align:left;
	padding-left:17px;
	@include breakpoint($sm){
		width:128px;
		padding-left:10px;
	}
	&:first-child{
		border-left:none;
	}
	&:before {
		content: '';
		display:inline-block;
		width:3px;
		height:3px;
		background-color:#000;
		vertical-align:middle;
		margin-right:13px;
		margin-top:-2px;
		@include breakpoint($sm){
			margin-right:8px;
		}
	}
}
.postSet{
	a{margin-left:0;}
	.row{
		@include breakpoint($sm){
			div+div{margin-top:5px;}
		}
	}
}
@include breakpoint($sm){
	.view-profile{
		>dl>dt{width:108px;}
		input[type=text]{
			width:100%;
			&.tel{width:56px;}
			&.emailId{width:116px;margin-bottom:4px;}
			&.emailAddr {width:49%;}
		}
		.select_email_js{width:49%;}
		.postSet{
			a{margin-left:0;margin-top:4px;}
			.row{
				div+div{margin-top:5px;}
			}
		}
	}
}
.view>dl>dd{
	padding-left:13px;
	padding-right:10px;
	border-right:1px solid $bdColor;
	&:last-child{
		border-right:none;
	}
}
.view>dl.w>dt,
.view>dl.w>dd{display:block;width:100%;}
.view>dl.w>dt{border-right:1px solid $bdColor;}
.view>dl+.dbData{border-top:1px solid $bdColor;}
.viewType2{
	>dl>dt{
		&:before{display:none;}
	}
}

.help{
	color:#f00;
	color:rgb(255, 10, 10);
	font-size:12px;
	margin:8px 0;

}

/* TABLES */
.ltable table,
.tb,//콘텐츠 테이블
.table{
	width:100%;
	border:none;
	border-top:2px solid $bdTopColor;
	text-align:center;
	margin-top:10px;
	td.nodata{padding:10px;font-size:16px;}
	> thead > tr > th,
	> tbody > tr > th,
	> tfoot > tr > th,
	> thead > tr > td,
	> tbody > tr > td,
	> tfoot > tr > td {
	  // border-color:#E5E5E5;
	  // border-width:0;
	}
	> thead > tr > th{
		background-color:#f9f9f9;
		color:#333333;
		font-size:16px;
		border-bottom:1px solid $bdColor;
		border-left:1px solid $bdColor;
		border-right:1px solid $bdColor;
		vertical-align:middle;
		line-height:1;
		font-weight:bold;
		padding:16px 0 14px;
		white-space:nowrap;
		background-clip: padding-box;
		&:first-child{
			border-right:1px solid $bdColor;
		}
		@include breakpoint($sm){
			padding-left:3px;
			padding-right:3px;
		}
	}
	> tbody > tr > td{
		border:none;
		border-bottom:1px solid $bdColor;
		border-left:1px solid $bdColor;
		border-right:1px solid $bdColor;
		padding-left:2px;
		padding-right:2px;
	}
	>thead>tr>th:first-child,
	>tbody>tr>td:first-child{border-left:none;}
	>thead>tr>th:last-child,
	>tbody>tr>td:last-child{border-right:none;}
	@include breakpoint($sm){
		.no,
		.name,
		.date,
		.count{
			display:none;
		}
	}
	&.nolinkType{
		td{
			padding-top:9px;
			padding-bottom:9px;
			&.tal{
				padding-left:20px;padding-right:10px;
			}
		}

	}
	th{text-align:center;}
	thead th{position:relative;}
	thead th::after {
		display:none;
	}
}

.tb{
	thead>tr>th{font-size:13px}
	tbody>tr>td{font-size:13px;
		padding:12px 3px;
	}
	tbody>tr>th{
		border-bottom:1px solid $bdColor;
	}
	td.tal{
		padding-left:4.6%;
	}
}
.tb-type2{
	text-align:left;
	tbody>tr>th{
		text-align:left;
		color:$pointcolor;
		padding-left:20px;
		width:168px;
	}
	tbody>tr>td{
		padding-left:18px;
	}
}
.tb-row{
	text-align:left;
	border-top:0px solid $bdTopColor;
	tbody>tr:first-child>*{
		border-top:2px solid $bdTopColor;
	}
	tbody>tr>th{text-align:left;padding-left:3.4%;
		background-color:$viewheadBgcolor;
		color:$pointcolor;
	}
	tbody>tr>td{
		padding:8px 27px;
	}
}
.ltable{
		display:block;
		overflow-x:auto;
		margin:10px 0 5px;
	table{
		margin:0;
		min-width:620px;
		tr{
			min-width:640px;
		}
		thead>tr>th{
			// white-space:normal;
		}
		thead>tr>th,
		tbody>tr>td{
			text-align:left;
			padding:13px 20px;
			font-size:13px;
			@include breakpoint($sm){
				padding:5px;
			}
		}
	}
}
.scollTable{
	@extend .ltable;
	table{
		thead>tr>th,
		tbody>tr>td{
			text-align:inherit;
			padding-left:4px;
			padding-right:4px;
		}
	}
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  // background:#fafafa;
}
.table-striped > tbody > tr:nth-child(odd):hover > td, 
.table-striped > tbody > tr:nth-child(odd):hover > th,
.table-hover > tbody > tr:hover{
	// background-color:#ffffe4;
}

table.table .tit{padding:0!important}
table.table .tit a{display:block;text-align:left;position:relative;padding:11px 5px 12px 5px;}
.bdList .tit a::before,
table.table .tit a::before{
	content:'';
	border-top:4px solid #ddd;
	border-left:4px solid #9f9f9f;
	border-right:4px solid transparent;
	border-bottom:4px solid transparent;
	display:inline-block;
	position:absolute;
	left:0;top:0;
	font:normal normal normal 14px/1 FontAwesome;
	font-size:inherit;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	display:none;
}
.bdList th.no{width:50px;}
.bdList th.voit{width:50px;}
.bdList th.view{width:50px;}
.bdList th.date{width:100px;}
.bdList th.date{width:100px;}
.table tr.disabled td{background-color:#eee;cursor:not-allowed;}
/**/
table.sm tr th{font-weight:normal;padding:2px}
table.sm tr td{font-weight:normal;padding-left:2px;padding-right:2px;}

table.tac th,
table.tac td{text-align:center;}
tr.tac>*{text-align:center;}

td .progress{margin-bottom:0;}

.ico-reply-ing{background-color:#707070;border:1px solid #707070;border-radius:3px;display:inline-block;padding:6px 8px;line-height:1;color:#fff;}
.ico-reply-end{background-color:#df8800;border:1px solid #df7106;border-radius:3px;display:inline-block;padding:6px 8px;line-height:1;color:#fff;}

.dbdata,
.dbData{
	padding:20px 0;
	.tab-content &{padding:0;}
	img{max-width:100%;
		height:auto !important;
	}
}

.category-1,
.category-2,
.category-3,
.category-4,
.category-5,
.category-7
{
	display:inline-block;
	min-width:60px;
	text-align:center;
	color:#fff;
	line-height:27px;
	font-size:14px;
	border-radius:50px;
}
.category-1{background-color:#4b579f;}
.category-2{background-color:#cd9659;}
.category-3{background-color:#79c4e7;}
.category-4{background-color:#d33671;}
.category-5{background-color:#88af28;}
.category-7{background-color:#2f7b13;}

.ico-download{
	background: url(/static/portal/img/ico_file@3x.png) 0 center no-repeat;
	padding-left:20px;
	background-size:20px;
	line-height:20px;
	display:inline-block;
	height:20px;
}

.cate-progress,
.cate-schedule,
.cate-close,
.status-ok,
.status-end,
.status-wait{
	display:inline-block;
	min-width:70px;
	padding:7px 13px 7px 11px;
	text-align:center;
	color:#fff;
	line-height:1;
	font-size:14px;
	border-radius:50px;
	background-color:#4b579f;
	&::before {
		content: '';
		display:inline-block;
		vertical-align:middle;
	}
}
.cate-progress{background-color:#4b579f;}
.cate-schedule{background-color:#8fc31f;}
.cate-close{background-color:#858585;}
.status-end{
	&::before {
		width:20px;
		height:10px;
		background: url(/static/portal/img/status-end.png) 0 0 no-repeat;
		@include breakpoint($sm){
			background: url(/static/portal/img/status-end@3x.png) 0 0 no-repeat;
			background-size:14px;
		}
	}
}
.status-ok{
	background-color:#b1885a;
	&::before {
		width:20px;
		height:10px;
		background: url(/static/portal/img/status-end.png) 0 0 no-repeat;
		@include breakpoint($sm){
			background: url(/static/portal/img/status-end@3x.png) 0 0 no-repeat;
			background-size:14px;
		}
	}
}
.status-wait{
	background-color:#8fc31f;
	&::before {
		width:18px;
		height:12px;
		background: url(/static/portal/img/status-wait@3x.png) 0 0 no-repeat;
		background-size:12px;
	}
}
a.lock{
	&::after {
		content: '';
		display:inline-block;
		width:16px;
		height:17px;
		background: url(/static/portal/img/ico-lock.png) 0 0 no-repeat;
		background-size:16px;
		vertical-align:middle;
		margin-left:4px;
		margin-top:-4px;
		@include breakpoint($sm){
			margin-top:0;
		}
		
	}
}

.table+.btnSet{margin-top:28px;margin-bottom:40px;}
@include breakpoint($sm){
	.pc{display:none;}
}