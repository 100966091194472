.calendarCtrl{
	text-align:center;
	// padding-right:105px;
	padding-top:14px;
	margin-bottom:26px;
	@include breakpoint($sm){
		margin-bottom:16px;
		padding-right:0;
		padding-top:0;
		text-align:center;
	}
	>*{display:inline-block;}
	>a{
		width:33px;
		height:33px;
		background:url(/static/portal/img/cal-left@3x.png) 0 0 no-repeat;
		background-size:33px;
		vertical-align:middle;
		@include breakpoint($sm){
			background-size:26px;
			height:26px;
			width:26px;
		}
	}
	.left{}
	.right{
		background-image:url(/static/portal/img/cal-right@3x.png);
	}
	span{
		vertical-align:middle;
		line-height:33px;
		font-size: 35px;
		font-family: "NanumSquare";
		color: rgb(75, 87, 159);
		padding:0 15px;
		@include breakpoint($sm){
			font-size:30px;
		}
	}
	em{
		font-weight:bold;
		font-family:'Verdana';
	}
}
.scheduleArea{
	position:relative;
	.scheduleInfor{
		border:1px solid #d2d2d2;
		// width:297px;
		// height:57px;
		padding:8px 13px;
		// position:absolute;
		// right:0;
		// top:0;
		overflow:hidden;
		margin-bottom:10px;
		span{
			float:left;
			width:20%;
			text-align:center;
			font-weight:bold;
		}
		@include breakpoint($sm){
		span{width:50%;text-align:left;
			white-space:nowrap;
		}
			position:static;
			width:auto;
			margin-bottom:10px;
		}
	}
}

.scheduleTable{
	margin-bottom:25px;
	table{
		width:100%;
		table-layout:fixed;
		thead{
			// background:#5360a9 url(/static/portal/img/cal-bg.png) center 0 repeat-x;
			background-color:#5360a9;
			background-size:auto 39px;
			th{
				color:#fff;
				font-family: "Verdana";
				font-size:15px;

				padding:13px 0;
				@include breakpoint($sm){
					font-size:12px;
					padding:8px 0;
				}
				&.sun{color:#f2b4b0;}
				&.sat{color:#dbf3ff;}
			}
		}
		tbody{
			td{
				border:1px solid #d7d4d8;
				padding:10px;
				vertical-align:top;
				@include breakpoint($sm){
					text-align:center;
					padding:6px 0;
				}
				a{
					display:block;
					font-size:12px;
					color:#333;
				}
			}
			tr:first-child td{border-top:none;}
			.day{
				width:25px;
				height:25px;
				// background-color:#ababab;
				border-radius:20px;
				color:#333333;
				font-weight:bold;
				display:inline-block;
				text-align:center;
				line-height:25px;
				margin-bottom:3px;
				@include breakpoint($sm){
					display:none;
				}
			}
			td.sun .day{color:#e60600;}
			td.sat .day{color:#00479d;}
			td.active .day{background-color:#4b579f;color:#fff;}
			button.day{
				display:none;
				@include breakpoint($sm){
					display:inline-block;
				}
			}

		}
	}
	.areaSet{
		@include breakpoint($sm){
			text-align:left;
		}
		>span{
			display:block;
			padding-left:5px;
			margin-bottom:3px;
		}
		@include breakpoint($sm){
			position:absolute;
			display:none;
			&.active{
				display:block;
				// right:0;
				// top:38px;
				left:0;
				width:80%;
				margin-left:10%;
				border:2px solid #4b579f;
				padding:5px;
				background-color:#fff;
				z-index:2;
				// overflow:hidden;
				text-overflow:ellipsis;
				white-space:nowrap;
			}
		}
	}
	// td:nth-child(1) .areaSet{left:0;right:inherit}
}

.area-seoul,
.area-kyungju,
.area-special,
.area-jungdongmaru,
.area-seouloutside{display:inline-block;
	em{
		display:inline-block;
		border-radius:2px;
		color:#fff;
		font-size:11px;
		width:16px;
		height:16px;
		text-align:center;
		line-height:16px;
		vertical-align:top;
		margin-top:1px;
	}
}
.area-seoul em{background-color:#e4007f;}
.area-kyungju em{background-color:#097c25;}
.area-special em{background-color:#c80000;}
.area-jungdongmaru em{background-color:#816749;}
.area-seouloutside em{background-color:#4b579f;}