.joinstep{
	position:relative;
	margin-bottom:25px;
	overflow:hidden;
	&::after {
		content: '';
		height:2px;
		width:100%;
		position:absolute;
		top:49px;
		left:0;
		background-color:#d5d5d5;
		@include breakpoint($sm){
			top:65/2-1px;
		}
	}
	ol{
		display:table;
		width:100%;
		table-layout:fixed;
	}
	li{
		display:table-cell;
		position: relative;
		text-align: center;
		font-size:16px;
		@include breakpoint($sm){
			font-size:13px;
		}
		color:#fff;
		position:relative;
		&:last-child::before,
		&:first-child::before{
			content: '';
			position:absolute;
			width:50%;
			height:99px;
			background-color:#fff;
			left:0;
			top:0;
			z-index:1;
		}
		&:last-child::before{
			left:inherit;
			right:0;
		}
	}
	li .set{
		display:inline-block;
		width:98px;
		height:99px;
		@include breakpoint($sm){
			width:65px;
			height:65px;
			padding-top:15px;
		}
		border-radius:50px;
		background-color:#8f8f8f;
		padding-top:30px;
		position:relative;
		z-index:2;
		}
	li .s{
		display:block;
		font-weight:bold;
		@include breakpoint($sm){
			font-weight:normal;
		}
		}
	li .t{}
	li.active .set{
		background-color:#4b579f;
	}
	li.last{
		padding-right: 0;
		background-image:none;
		}
}
.agg-set{
		margin: 0 0 10px;
		padding: 9px 12px;
		border:1px solid #e1e1e1;
		background-color: #fcfcfc;
		.set{
			height: 242px;
			overflow: scroll;
			overflow-x:hidden ;
			padding-left: 12px;
			font-size:13px !important;
			h2,
			h3,
			h4{font-size:1em;background-image:none;padding:0;margin:10px 0 7px;color:inherit;}
			em,strong,p{font-size:1em;}
		}
	}
.jointext{
  	font-family: "NanumSquare";
  	margin-bottom:27px;
  .t{
  	font-size: 24px;
  	color: rgb(75, 87, 159);
  	font-weight: bold;
  	line-height: 1.5;
  }
  .t+p{
  	font-size:18px;
  }
  &.type2{
  	text-align:center;
  	.t+p{
  		font-size:18px;
  	}
  }
  &.type3{
  	
  	.t+p{
  		font-size:18px;
  	}
  }
}
.aggtotal{
	text-align:center;
	label{
		font-size:14px;
		font-weight:bold !important;
	}
}