#faqList {
	position:relative;
	border-top:3px solid $bdTopColor;
	dt,
	dd{
		position:relative;
		padding:10px 10px 12px 94px;
		border-bottom:1px solid $bdColor;
	}
	dt{
		padding-top:5px;
		padding-bottom: 0;
		font-size: 14px;
		color: rgb(75, 87, 159);
		line-height: 1.429;
		min-height:44px;
		.qna_img{
			background-color:#4b579f;
		}
		&.open button{
			text-decoration:underline;
			font-weight:bold;
		}
	}
	dt button {
		display:block;
		line-height:18px;
		background-color: transparent;
		border: 0;
		padding:10px 0;
		width: 100%;
		text-align: left;
	}
	dd {
		display:none;
		background:#f9f9f9;
		.qna_img{
			background-color:#c49a6c;
		}
	}
	.qna_img{
		position:absolute;
		left:22px;
		top:14px;
		display:inline-block;
		font-weight:bold;
		color:#fff;
		font-size:13px;
		width:22px;
		height:22px;
		text-align:center;
		line-height:22px;
		border-radius:30px;
	}
}