#sm{
	position:relative;
	>ul{
		overflow:hidden;

		>li{
			float:left;
			width:25%;
			padding:13px 20px;
			border-right:1px solid #dedede;
			position:relative;
			&.a7,
			&.a8,
			&.a9{
				div{height:auto;}
			}

			@include breakpoint($lg){
				&.a4,
				&.a8{border:none;}
			}
			
			@include breakpoint($sm){
				float:none;
				width:auto;
				padding:0;
				margin-top:5px;
				border:none;
				&:nth-child(odd){
					clear:left;
				}
				>div{
					height:auto !important;
				}
			}
			&.heightauto{
				div{height:auto !important;}
			}
			.title a{
				text-align:center;
				padding:14px 0 11px;
				border-radius:30px;
				border:2px solid #4b579f;
				display:block;
			  font-size: 22px;
			  font-family: "NanumSquare";
			  color: rgb(75, 87, 159);
			  font-weight: bold;
			  line-height: 1;
			  display:block;
			}
			.set{
				>ul{
				padding-left:20px;
					>li{
						padding:10px 0;
						>a{
							display:block;
							color:#333;
							font-size:16px;
							font-weight:bold;
							@extend .bu4;
						}
						ul{
							padding-top:10px;
							margin-bottom:-10px;
							li{
								a{
									display:block;
									font-size:13px;
									color:#797979;
									padding:5px 0 5px 12px;
									font-weight:bold;
								}
							}
						}
					}
				}
			}
		}
		@include breakpoint($md){
			&::after {
				content: '';
				height:100%;
				width:33.33%;
				position:absolute;
				left:33.33%;
				top:0;
				border-left:1px solid #dedede;
				border-right:1px solid #dedede;
			}
			>li{
				width:33.33%;
				border:none;
			}
		}
	}
}