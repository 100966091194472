$bdColor:#bfbfbf;
.bdView {
	border-top:2px solid $bdTopColor;
	.subject {
		font-size: 18px;
		font-family: "Malgun Gothic";
		color: rgb(51, 51, 51);
		font-weight: bold;
		background-color:#f9f9f9;
		margin:0;
		padding:12px 4px;
		border-bottom:1px solid $bdColor;
		>span{
			float:right;
			margin-right:2px;
			margin-top:-11+7px;
		}
	}
	dl {
		border-bottom:1px solid $bdColor;
		padding:14px 4px;
		>*{display:inline-block;}
		dt {font-weight:bold;padding-right:11px;
			border-right:1px solid #e2e2e2;
		}
		dd {min-width:94px;
			padding:0 10px;
		}
		@include breakpoint($sm){
			display:table;
			width:100%;
			&>*{display:table-cell;}
			dt{
				padding:0 10px;
			}
			dd{min-width:inherit;}
		}
	}
	&.type-qna{
		.dbData{
			border-bottom:2px solid $bdTopColor;
		}
	}
	dl.file {
		border-top:1px solid $bdColor;
		border-bottom:none;
		dt {position:absolute;}
		dd {
			a{
				padding-left:26px;
			}
		}
	}
}

.anwserSet{
	border-style:solid;
	border-width:2px 0;
	border-color:$bdTopColor;
	padding:13px 0;
	@include breakpoint($sm){
		padding:11px 0;
	}
	.status-ok{
		margin:0;
		float:left;
		@include breakpoint($sm){
			float:none;
			margin-bottom:15px;
		}
	}
	>div{
		margin-left:93px;
		@include breakpoint($sm){
			margin:0;
		}
	}
}
