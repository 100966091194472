.experiencelist{
	.row{
		margin-bottom:12px;
		>div{
			@include breakpoint($sm){
				margin-bottom:12px;
			}
			a{
				display:block;
				border:1px solid #d7d8de;
				position:relative;
				&:hover,
				&:focus{
					border-color:#a1a7cd;
					.end{display:block;}
				}
				.end{
					display:none;
					content: '';
					background-color:rgba(0,0,0,0.6);
					position:absolute;
					z-index:1;
					left:0;
					top:0;
					width:100%;
					height:100%;
					text-align:center;
					// display: flex;
					// align-items: center;
					// display:table-cell;
					// vertical-align:middle;
					span{
						position:absolute;
						top:50%;
						left:0;
						right:0;
						margin: -32px auto;
						align-items:center;
						display:inline-block;
						z-index:2;
						color:#fff;
						text-align:center;
						border:2px solid #fff;
						padding:10px 20px;
						font-size:15px;
						font-weight:bold;
						width:190px;
					}
				}
				span.img{
					position:relative;
					overflow:hidden;
					display:block;
					&::after {
						content: '';
						display:block;
						padding-top:47.28434504792332%;
					}
					img{
						position:absolute;
						top:0;
						left:0;
						width:100%;
					}
				}
				.textSet{
					padding:16px 16px 11px 16px;
					display:block;
				}
				span.tset{
					margin-bottom:16px;
					display:block;
					span{
						display:block;
					}
					.t{
							font-size: 18px;
							font-family: "NanumSquare";
							color: rgb(75, 87, 159);
							font-weight: bold;
							line-height: 1.2;
							padding:0 0 3px;
					}
					.d{
							font-size: 13px;
							color: rgb(51, 51, 51);
							line-height: 1.2;
							padding-bottom:20px;
							height:32px;
							overflow:hidden;
							@include breakpoint($sm){
							}
					}
				}
				dl{
					margin-bottom:4px;
					overflow:hidden;
					dt{
						background-color:#4b579f;
						float:left;
						color:#fff;
						font-weight:bold;
						padding:2px 4px;
					}
					dd{margin-left:42px;}
					.cost{
						font-weight:bold;
						// color:#ff4800;
					}
				}
			}
		}
	}
}