@mixin bu4() {
	position:relative;
	padding-left:14px;
	&:before {
		content: '';
		width:3px;
		height:3px;
		background-color:#333333;
		position:absolute;
		left:0;
		top:6px;
	}
}
h2{
	font-size: 18px;
	color: rgb(51, 51, 51);
	font-weight: bold;
	line-height:1;
	margin:30px 0 15px;
	&+p{margin-top:-15px;}
}

[class^=bu1],
.bu1{
		font-size: 24px;
		font-family: "NanumSquare";
		color: rgb(75, 87, 159);
		font-weight: bold;
		line-height: 1.2;
}
.bu1-view{
	border-bottom:2px solid #4b579f;
	padding-bottom:10px;
}
.bu2{
	margin:20px 0 0 5px;
	font-size: 18px;
	color: rgb(51, 51, 51);
	font-weight: bold;
	line-height: 1.2;
}
.bu5{
	position:relative;
	padding-left:10px;
	margin-left:5px;
	&:before {
		content: '- ';
		display:inline-block;
		width:10px;
		vertical-align:middle;
		// width:3px;
		// height:3px;
		// background-color:#333333;
		position:absolute;
		left:0;
		top:-2px;
	}
}
.colred{
	color:#ff0a0a;
}

// 콘텐츠 불릿
.blt1{
	font-size: 20px;
	color: rgb(51, 51, 51);
	font-weight: bold;
	margin:30px 0 14px 0;
	&+p{margin-top:0;}
}
.blt2{
	background:url(/static/portal/img/ico_o.png) 0 center no-repeat;
	@include breakpoint($sm){background:url(/static/portal/img/ico_o@3x.png) 0 center no-repeat;
		background-size:11px;
	}
	background-size:11px;
	font-size: 16px;
	color:#4b579f;
	font-weight: bold;
	padding-left:18px;
	margin:15px 0 6px;
	&+.blt3{margin-top:5px;}
}
.blt3{
	@include bu4();
	margin-left:10px;
	padding-left:8px;
	margin-top:30px;
	margin-bottom:5px;
}
.bu4{
	@include bu4();
	padding-left:8px;
}
.m1{
	margin-left:18px;
}
dl.m1{
	*{margin-bottom:5px;}
}
em{font-weight:bold;}

.list{
	li{
		@extend .bu5;
	}
}
.list2{
	margin-top:10px;
	@extend .m1;
	li{
		@extend .bu4;
		&+li{margin-top:4px;}
	}
}