@mixin bg($x:0,$y:0) {
	background:#fff url(/static/portal/img/subcommon.png) #{$x+px} #{$y+px} no-repeat;
	background-size:600px 600px;
}
body{
	background-color:#f0eff2;
	// max-width:768px;
	&::before {
		content: '';
		border-top:32px solid #4b579f;
		position:absolute;
		width:100%;
		left:0;
		top:0;
		height:110px;
		background-color:#fff;
		@include breakpoint($smd){
			border-top-width:3px;
			height:80px;
		}
	}
}
#wrap{
	max-width:1260px;
	margin: 0 auto;
	position:relative;
}
.global{
	@include breakpoint($smd){
		display:none;
	}
	float:right;
	height:32px;
	border-right:1px solid #818abc;
	margin-right:-252px;
	>li{
		float:left;
		border-left:1px solid #818abc;
		position:relative;
		a{
			display:block;
			padding:0 12px;
			color:#fff;
			line-height:1;
			font-size:13px;
			line-height:32px;
			&.lang{
				&::after {
					content: '';
					width:0;
					height:0;
					border-left:4px solid transparent;
					border-right:4px solid transparent;
					border-top:4px solid #fff;
					display:inline-block;
					vertical-align:middle;
					margin-left:5px;
				}
			}
		}
		&.open{
			div{
				display:block;
			}
		}
		div{
			position:absolute;
			width:100%;
			display:none;
			z-index:980;
			// background-color:#4b579f;
			border:1px solid #818abc;
			background-color:#fff;
			border-top:none;

			a{
				color:#4b579f;
				white-space:nowrap;
				display:block;
				text-align:center;
			}
		}
	}
}
#header{
	height:142px;
	padding:0 252px 0 167px;
	margin-bottom:130px;
	.smHeaderCtrl{
		display:none;
		@include breakpoint(240px 1023px){
			display:block;
			float:right;
			padding:25px 4px 0 0;
			button{
				width:36px;
				height:36px;
				float:left;
				background:url(/static/portal/img/m_top_search.png) 0 0 no-repeat;

				@include breakpoint($smd){
					@include image2x('/static/portal/img/m_top_search@3x.png',36px);
				}
			}
			.mobileSearchOpen{}
			.mobileNavOpen{
				background-image:url(/static/portal/img/m_top_navopen.png);
				@include breakpoint($smd){
					@include image2x('/static/portal/img/m_top_navopen@3x.png',36px);
				}
			}
		}
	}
	@include breakpoint($smd){
		height:83px;
		margin-bottom:84px;
		padding:0;
	}
	@include breakpoint($smd){
		.mobileTshOpen & .tshSet{
			top:80px;
			background-color:#fff;
			padding:0 40px 4px 10px;
			position:absolute;
			left:0;
			width:100%;
			z-index:20;
		}
	}
	.tsh{
		@include breakpoint($smd){
			display:none;
			.mobileTshOpen &{display:block;}
			position:static;
			z-index:10;
			width:100%;
			padding-right:0;
		}
		position:absolute;
		right:0;
		top:67px;
		width:252px;
		padding-right:40px;
		padding-left:10px;
		border:3px solid #4b579f;
		border-radius:20px;
		input{
			text-indent:0;
			width:100%;
			height:34px;
			border:none;
			background-color:transparent;
			@include breakpoint($smd){
				padding-right:40px;
			}
			&+button{
				@include breakpoint($smd){
					right:40px;
					top:0;
				}
			}
		}
		button{
			position:absolute;
			right:-3px;
			top:-3px;
			width:40px;
			height:40px;
			@include bg(-194, -411+22);
			background-color:transparent;
		}
		.mobileTshClose{
			display:none;
			@include breakpoint($smd){
				display:block;
				top:0;
				width:40px;height:40px;
				background:#fff url(/static/portal/img/m_search_close.png) 0 0 no-repeat;
			}
		}
	}
}
.logo{
	position:absolute;
	top:64px;
	left:0;
	a{display:block;}
	@include breakpoint($smd){
		top:23px;
		left:10px;
		a{
			width:167px;
			height:47px;
			background:url(/static/portal/img/logo@3x.png) 0 0 no-repeat;
			background-size:167px;
			img{display:none;}
		}
	}
	

}
#gnb{
	padding:67px 0 0 0;
	text-align:center;
	display:block;
	>ul{
		display:inline-block;
		>li{
			display:inline-block;
			padding:0 8+5px;
			>a{
				display:block;
				padding:10px 0;
				font-size: 20px;
				font-family: "NanumSquare";
				color: rgb(0, 0, 0);
				line-height: 1;
				text-align: center;
				border-top:3px solid #fff;
				&:hover,
				.on &
				{
					border-top:3px solid #4b579f;
				}
			}
			@include breakpoint($lg2){
				padding:0 8px;
				a{font-size:18px;}
			}
			&.hover{
				>a{border-top:3px solid #4b579f;}
				ul{
					display:block;
					border:1px solid #a1a7cd;
					background-color:#fff;
					text-align:left;
					z-index:2;
					width:160px;
					margin-left:-43px;
					padding:6px 0;
					&.menuNo200022{margin-left:-20px;}
					li{
						a{
							display:block;
							font-size:14px;
							padding:6px 0 6px 14px;
							&::before{
								content: '- ';
							}
							&:hover,
							&:focus{
								text-decoration:underline;
								color:#4b579f;
								font-weight:bold;
							}
						}
						ul{
							display:none;
						}
					}
				}
			}
		}
	}
	.allmenuopen{
		background:url(/static/portal/img/subcommon.png) 0 -300px no-repeat;
		width:24px;
		height:17px;
		display:inline-block;
	}

	ul>li>ul{
		position:absolute;
		display:none;
		li{
			a{
				&:hover,
				.on &{
				}
			}
		}
	}
}
@include breakpoint($smd){
	#gnb{
		display:none;
		>ul{
			text-align:center;
			>li{
				display:inline-block;
				padding:0 8+5px;
				>a{
					display:block;
					padding:10px 0;
					font-size: 21px;
					font-family: "NanumSquare";
					color: rgb(0, 0, 0);
					line-height: 1;
					text-align: center;
					border-top:3px solid #fff;
					&:hover,
					.on &
					{
						border-top:3px solid #4b579f;
					}
				}
			}
		}
		ul>li>ul{
			position:absolute;
			display:none;
			li{
				a{
					&:hover,
					.on &{
					}
				}
			}
		}
	}
}
#container{
	position:relative;
	display:table;
	width:100%;
	@include breakpoint($sm){
		display:block;
	}
	>.lnb,
	>main
	{
		display:table-cell;
		vertical-align:top;

	}
	>.lnb{
		padding-bottom:60px;
		@include breakpoint($md){display:none; }
		@include breakpoint($sm){display:none; }
	}
	>main{
		border-left:10px solid #f0eff2;
		background-color:#fff;
		@include breakpoint($sm){
			display:block;
			border-right:10px solid #f0eff2;

		}
	}
}
.lnb{
	width:220px;
	margin-bottom:70px;
	@include bg(0,-450);
	p{
		font-size:11px;
		color:#c4c7dc;
		text-align:center;
		padding:30px 0 9px;
	}
	>h1{
		  font-size: 30px;
		  font-family: "NanumSquare";
		  color: rgb(255, 255, 255);
		  line-height: 1;
		  text-align: center;
		  margin-bottom:75px;
	}
	>ul{
		margin:0 15px;
		border-top:1px solid #a1a7cd;
		>li{
			border-bottom:1px solid #a1a7cd;
			>a{
				display:block;
				padding:14px 13px;
				font-size: 14px;
				// font-family: "MalgunGothicRegular";
				color: rgb(51, 51, 51);
				position:relative;
				}
			&>a:hover,
			&.on>a
			{

				&::after {
					content: '';
					width:13px;
					height:10px;
					@include bg(-200,-200);
					position:absolute;
					right:10px;
					top:15px;
				}
			}
		}
	}
	ul>li>ul{
		background-color:#f0eff2;
		padding:7px 0;
		li{
			a{
				display:block;
				padding:6px 13px;
				font-size: 13px;
				// font-family: "MalgunGothicRegular";
				color:#606798;
				line-height: 1.2;
				&::before {
					content: '- ';
					display:inline;
				}
				&:hover,
				.on &{
					color:#4b579f;
					text-decoration:underline;
					font-weight:bold;
				}
			}
		}
	}
	aside{
		h1{
			  font-size: 13px;
			  font-family: "Malgun Gothic";
			  color: rgb(75, 87, 159);
			  font-weight: bold;
			  line-height: 1.846;
			  margin:70px 0 10px 15px;
		}
		ul{
			margin:0 15px;
			overflow:hidden;
			border:1px solid #d7d8de;
			border-bottom:none;
			li{
				width:50%;
				float:left;
				text-align:center;
				&:nth-child(even) a{
						border-left:1px solid #d7d8de;
					}
				a{
					display:block;
					font-size: 12px;
					color: rgb(51, 51, 51);
					height:93px;
					border-bottom:1px solid #d7d8de;
					&:hover,
					&:focus{
						font-weight:bold;
						text-decoration:underline;
					}
				}
			}
		}
	}
}
.favico1,
.favico2,
.favico3,
.favico4,
.favico5,
.favico6{
	@include bg();
	display:block;
	width:93px;
	height:65px;
}
.favico1{
	background-position:0 0;
}
.favico2{
	background-position:-94px 0;
}
.favico3{
	background-position:0 -94px;
}
.favico4{
	background-position:-94px -94px;
}
.favico5{
	background-position:0 -188px;
}
.favico6{
	background-position:-94px -188px;
}
.mainSet{
	background-color:#fff;
	padding:10px 30px 30px;
	transition: all .25s ;
	@include breakpoint($smd){
		padding:10px;
	}
	.subNav{
		position:absolute;
		width:100%;
		top:-130px;
		left:0;
		text-align:center;
		transition: all .25s ;
		@include breakpoint($smd){
			top:-83px;
		}
		h1{
			padding-top:48px;
			font-size: 36px;
			  font-family: "NanumSquare";
			  color: rgb(75, 87, 159);
			  line-height: 1.2;
			position:relative;
			transition: all .25s ;
			@include breakpoint($smd){
				padding-top:29px;
				font-size:30px;
				font-weight:normal;
			}
			&::before {
				content: '';
				@include bg(-250,-400);
				background-color:transparent;
				width:28px;
				height:30px;
				position:absolute;
				left:50%;
				margin-left:-14px;
				top:0;
				transition: all .25s ;
				@include breakpoint($smd){
					height:20px;
				}
			}
		}
		a{
			position:absolute;
			width:60px;
			height:60px;
			top:35px;
			transition: all .25s ;
			@include breakpoint($smd){
				display:none;
			}
		}
		.left{
			@include bg(-200,-300);
			background-color:transparent;
			left:0;
		}
		.right{
			@include bg(-300,-300);
			background-color:transparent;
			right:0;
		}
	}
}
.locationSet{
	@include breakpoint($sm){
		display:none;
	}
	overflow:hidden;
	border-bottom:2px solid #4b579f;
	padding-bottom:10px;
	margin-bottom:30px;
	>p{
		float:left;
		  font-size: 12px;
		  // font-family: "MalgunGothicRegular";
		  color: rgb(119, 119, 119);
		  line-height: 1.2;
		&::before {
			content: '';
			@include bg(-200,0);
			width:12px;
			height:12px;
			display:inline-block;
			margin-right:9px;
		}
		span{
			&::before {
				content: '';
				@include bg(-250,0);
				width:7px;
				height:7px;
				display:inline-block;
				margin:-2px 5px 0 1px;
			}
			em{
				font-weight:bold;
				color:#4b579f;
			}
		}
	}
	.right{
		float:right;
		>div>button,
		>a{
			width:31px;
			height:26px;
			vertical-align:top;
			float:left;
		}
		>div>button{
			@include bg(-346,-150);
			&.active{
				@include bg(-311,-150);
				&+.snsWrap{display:block;}
			}
		}
		>a{
			@include bg(-383,-150);
		}
		>div{
			position:relative;
			float:left;
			.snsWrap{
				position:absolute;
				right:33px;
				top:0;
				width:110px;
				display:none;
				a{
					width:20px;height:20px;
					float:left;
					margin-right:2px;
					width:20px;
					height:26px;
					&.sns-naver{
						@include bg(-200,-150);
					}
					&.sns-instar{
						@include bg(-222,-150);
					}
					&.sns-tweeter{
						@include bg(-244,-150);
					}
					&.sns-facebook{
						@include bg(-266,-150);
					}
					&.sns-kakaostory{
						@include bg(-288,-150);
					}
				}
			}
		}
	}
}
.footerSet{
	margin-top:40px;
	background-color:#fff;
	footer{
		max-width:1300px;
		margin: 0 auto;
		border-top:2px solid #4d4a82;
		&::after {
			content: '';
			display:table;
			clear:both;
		}
	}
	@include breakpoint($md){
		.both{
			padding-right:256px+10;
			position:relative;
		}
	}
	.fnav{
		padding:0 20px;
		overflow:hidden;
		border-bottom:1px solid #c8c8c8;
		@include breakpoint($sm){
			padding-top:8px;
		}
		ul{
			float:left;
			margin-left:-16px;
			@include breakpoint($sm){
				float:none;
				text-align:center;
			}
			li{
				float:left;
				position:relative;
				@include breakpoint($sm){
					float:none;
					display:inline-block;
				}
				&:first-child a::after {
					display:none;
				}
				a{
					display:block;
					  font-size: 13px;
					  // font-family: "MalgunGothicRegular";
					  color: rgb(51, 51, 51);
					  line-height: 1;
					  padding:14px 16px 12px;
					  @include breakpoint($sm){
					  	padding:4px 10px;
					  }
					  &::after {
					  	content: '';
					  	border-left:1px solid #dcdcdc;
					  	position:absolute;
					  	left:0;
					  	top:14px;
					  	height:12px;
					  	@include breakpoint($sm){top:4px;}
					  }
				}
			}
		}
		.right{
			float:right;
			padding-top:7px;
			padding-bottom:7px;
			@include breakpoint($sm){
				float:none;
				text-align:center;
			}
			a{
				width:24px;
				height:24px;
				float:left;
				height:27px;
				width:24px;
				margin-right:8px;
				background:url(/static/portal/img/footersnsSet.png) 0 0 no-repeat;
				@include image2x('/static/portal/img/footersnsSet@3x.png',268px);
				@include breakpoint($sm){
					float:none;display:inline-block;
				}
			}
			.pixnet{		background-position:0 0;width:66px;}
			.ameba{			background-position:-77px 0;}
			.weibo{			background-position:-111px 0;}
			.naverblog{	background-position:-147px 0;}
			.youtube{		background-position:-179px 0;}
			.facebook{	background-position:-212px 0;}
			.instagram{	background-position:-244px 0;}
		}
	}
	.fsSet{
		float:right;
		padding-top:15px;
		padding-right:20px;
		@include breakpoint($md){
			position:absolute;
			right:0;
			top:0;
		}
		>*{
			background-color:#4d4a82;
			border:none;
			height:32px;
			float:left;
			margin-left:1px;
			color:#fff;
		}
		select{
			min-width:194px;
			border-radius:0;
		}
		button{
			width:40px;
			line-height:32px;
		}
		@include breakpoint($sm){
			float:none;
			padding-left:10px;
			padding-right:51px;
			text-align:center;
			width:100%;
			overflow:hidden;
			margin: 0 auto;
			position:relative;
			>*{float:none;margin:0;}
			select{width:100%;}
			button{position:absolute;right:10px;top:15px;}
		}
	}
	address{
		float:left;
		padding:20px 0 60px 20px;
		font-size: 13px;
		color: rgb(51, 51, 51);
		line-height: 1.429;
		font-style:normal;
		p{margin-bottom:10px;font-weight:normal;margin-top:2px;}
		em{
			font-weight:bold;
			display:block;
			font-size:14px;
		}
		.copyright{
			color:#767676;
		}
		@include breakpoint($sm){
			float:none;
			text-align:center;
			padding-left:10px;
			padding-right:10px;
		}
	}
	.wamark{float:right;padding-top:21px;padding-right:20px;
		@include breakpoint($md){margin-top:32px+21;}
		@include breakpoint($sm){display:none;}
	}
}