.tab{
	margin-top:15px;
	margin-left:-1px;
	margin-bottom:15px;
	@include breakpoint($sm){
		margin-bottom:11px;
		margin-top:10px;
	}
	ul{
		display:table;
		width:100%;
		text-align:center;
		table-layout:fixed;
		@include breakpoint($sm){
			table-layout:inherit;
			.c200060 &,
			.l6 &{
				display:block;
				overflow:hidden;
				li{
					display:block;
					float:left;
					width:33.3333%;
					margin-bottom:2px;
				}
			}
			.c200060 &{
				li{width:50%;}
			}
		}
	}
	li{
		display:table-cell;
		padding-left:1px;
		a{
			font-size:14px;
			display:block;
			background-color:#f0eff2;
			border:1px solid #d7d8de;
			position:relative;
			line-height:1;
			padding:12px 0;
			span{
				@include breakpoint($sm){
					display:none;
				}
			}
		}
		&.active a,
		&.on a{
			background-color:#c49a6c;
			border-color:#c49a6c;
			color:#fff;
			font-weight:bold;
			&::after {
				content: '';
				width:0;
				height:0;
				position:absolute;
				bottom:-1px;
				left:50%;
				margin-left:-2px;
				width:0;
				height:0;
				border-left:5px solid transparent;
				border-right:5px solid transparent;
				border-bottom:5px solid #fff;
			}

		}
	}
}
.tab-content{
	.tab-pane{
		display:none;
		&.active{
			display:block;
		}
	}
}

.c200060{
	.l2{width:180px;}
}