/*paging*/
$pagingActiveBg : #4b579f;
$pagingbdcolor : #b2b4bf;

.paginationSet{text-align:center;margin:26px 0 30px;}
.paging {text-align: center;margin-top:30px;}
.pagination em {font-style: normal}
.pagination{display:inline-block;padding-left:0;text-align:center;

}
.pagination>li{display:inline;}
.pagination>li>a,
.pagination>li>span{
	position:relative;
	float:left;
	padding: 0;
	text-decoration:none;
	width:40px;
	height:40px;
	line-height:40px;
	font-size:15px;
	// color:#58524b;
	// background-color:$pagingActiveBg;
	border:1px solid $pagingbdcolor;
	margin-left:-1px;
}
.pagination>li.first>*,
.pagination>li.prev>*,
.pagination>li.next>*,
.pagination>li.end>*{
background: url(/static/portal/img/paging.png) 0 0 no-repeat;
@include breakpoint($sm){
	background-image:url(/static/portal/img/paging@3x.png);
	background-size:auto 40px;
}
width:70px;
font-size:13px;
padding-left:29px;
text-align:left;
}
.pagination>li.first>* 	{width:68px;}
.pagination>li.prev>* 	{background-position: -70px 0;margin-right:11px;}
.pagination>li.next>* 	{padding-left:16px;background-position: -550px 0;margin-left:11px;}
.pagination>li.end>* 	{padding-left:8px;background-position: right 0}

.pagination>li .t{position: absolute;width: 1px;height: 1px;margin: -1px;padding: 0;overflow: hidden;clip: rect(0,0,0,0);border: 0}
.pagination>li:first-child>a,
.pagination>li:first-child>span {margin-left: 0;}
.pagination>li:last-child>a,
.pagination>li:last-child>span {}
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
z-index: 2;
text-decoration:underline;
}
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
z-index: 3;
color: #fff;
cursor: default;
font-weight:bold;
background-color:$pagingActiveBg;
text-decoration:underline;
}
.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {cursor: not-allowed;opacity:0.7;border-color:#d8d8d8;display:none;}
@include breakpoint($sm){
	.paginationSet > ul > li.i > a{
		width:40px;
		border:none;
	}
	.paginationSet > ul > li.i > a > span.s{
		position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0
	}
	.pagination > li.next > *{background-position:-580px 0;}
}