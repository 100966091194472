.allmenuActive{
	.allmenuBg{
		position:absolute;
		left:0;top:0;
		width:100%;
		height:100vh;
		background-color:#000;
		background-color:rgba(0,0,0, 0.7);
		z-index:9998;
	}
	.allmenuSet{display:block;}
	.allmenuClosebtn{
		background: url(/static/portal/img/allmenuclose.png) 0 0 no-repeat;
		width:43px;
		height:43px;
		position:absolute;
		top:-54px;
		right:0;
	}
}
.allmenuSet{
	display:none;
	position:absolute;
	width:100%;
	border:3px solid #4b579f;
	border-radius:9px;
	left:0;
	top:140px;
	padding:40px 37px;
	z-index:9999;
	background-color:#fff;
	>ul{
		display:table;
		width:100%;
		table-layout:fixed;
		>li{
			display:table-cell;
			padding:0 10px;
			&:last-child{width:206px;}
			a{display:block;}
			>a{
				padding-bottom:20px;
				border-bottom:2px solid #a1a7cd;
				font-size: 21px;
				font-family: "NanumSquare";
				color: rgb(0, 0, 0);
				line-height: 1;
				text-align: center;
			}
			>ul{
				padding-top:12px;
				>li{
					>a{
						font-size:15px;
						color:#4b579f;
						padding:5px 0 6px 9px;
						background:url(/static/portal/img/bu_allmenu2depth.png) 0 center no-repeat;
					}
					>ul{
						li{
							a{
								font-size:14px;
								color:#777;
								padding:2px 0 3px 9px;
								&::before {
									content: '- ';
								}
							}
						}
					}
				}
			}
		}
	}
}

@include breakpoint($smd){
	.mobileMenuOpen{
		.mobileMenu{display:block;}
		.allmenuBg{
				position:absolute;
				left:0;top:0;
				width:100%;
				height:200vh;
				background-color:#000;
				background-color:rgba(0,0,0, 0.7);
				z-index:9998;
			}
		.allmenuopen{display:none !important}
	}
}
.mobileMenu .left{display:none;}
.mobileMenuOpen .mobileMenu{
	// display:none;
	width:300px;
	position:absolute;
	right:0;
	top:30px;
	border-radius:8px 0 0 8px;
	background-color:#4b579f;
	z-index:9999;
	.left{
		display:block;
		float:left;
		width:80px;
		.mobileMenuClose{
			width:35px;
			height:35px;
			display:block;
			background: url(/static/portal/img/allmenuclose@3x.png) 0 0 no-repeat;
			background-size:35px;
			margin:20px auto 15px;
		}
		.loginbtn{
			width:60px;
			margin: 0 auto 150px;
			a{font-size:12px;color:#4b579f;background-color:#fff;display:block;padding:5px 0;margin-bottom:1px;text-align:center;}
		}
		.serviemenu{
			a{
				margin: 0 auto 2px;
				color:#fff;
				text-align:center;
				width:60px;
				height:60px;
				border-radius:60px;
				border:1px solid #818abc;
				display:block;
				line-height:60px;
				font-size:12px;
				&.l{
					line-height:1.2;
					padding-top:15px;
				}
			}
		}
		
	}
	#gnb{
		display:block;
		float:right;
		width:220px;
		background-color:#fff;
		border-radius:8px 0 0 8px;
		margin:0;
		padding:22px 0 0 26px;

		>ul{
			display:block;
			width:170px;
			margin:0;
			>li{
				float:none;
				display:block;
				padding:0;
				>a{
					font-size: 21px;
					font-family: "NanumSquare";
					color:#fff;
					padding:14px 0;
					text-align:center;
					background-color:#4b579f;
					border:none;
					&:hover,
					&:focus{border:none;}
				}
				>ul{
					display:block;
					position:static;
					text-align:left;
					margin:0;
					border:none;
					padding:12px 0;
					>li{
						>a{
							padding:5px 0 5px 19px;
							display:block;
							background:url(/static/portal/img/bu_allmenu2depth.png) 10px center no-repeat;
							font-size:15px;
							color:#4b579f;
						}
						ul{
							display:block;
							position:static;
							li{
								a{
									display:block;
									font-size:14px;
									padding:2px 0 2px 19px;
									color:#777;
									&::before {
										content: '- ';
									}


								}
							}
						}
					}
				}
			}
		}
	}
}