@import "breakpoint";
@mixin image2x($image, $width:auto, $height:auto) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: #{$width} #{$height};
  }
}

$xs                 : max-width 320px;
$sm                 : max-width 767px;
$md                 : 768px 1023px;
$smd                : max-width 1023px;
$lg                 : 1024px;
$lg2 								: 1024px 1130px;
$xl                 : max-width 1199px;

$linkColor          : #000;

// board list color
$bdColor            : #f3f3f3;
$bdColor            : #d7d8de;
$bdTopColor         : #4b579f;
$cellhovercolor		: #f4f4f4;

// board view color
$viewbdColor    : #dddddd;
$viewbdTopColor : #494b50;
$viewheadBgcolor : #f4f4f4;
$viewheadcolor : #333333; 

// input
$formHeight         : 30px;
// button
$btnHeight          : $formHeight;

$color_black        : #000;
$color_deepgray     : #333;
$color_orange       : #e73100;
$color_blue         : #3679C1;
$color_cobalt       : #02728D;
$color_red          : #c10101;
$color_green        : #3A7525;
$pointcolor					: #4b579f;

/* font color */
.fc_black    {color : $color_black}
.fc_deepgray {color : $color_deepgray}
.fc_orange   {color : $color_orange}
.fc_blue     {color : $color_blue}
.fc_cobalt   {color : $color_cobalt}
.fc_red      {color : $color_red}
.fc_green    {color : $color_green}

$fontSet            : "맑은고딕","malgun gothic","AppleGothic","돋움","dotum",Helvetica,Arial,sans-serif;
//"Nanum Gothic",
//h1~h6
$headingFont        : "Noto Sans";

@mixin bu4() {
	&:before {
		content: '';
		display:inline-block;
		width:3px;
		height:3px;
		background-color:#000;
		vertical-align:middle;
		margin-right:8px;
		margin-top:-2px;
	}
}