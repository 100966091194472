.totalsh{
	background-color:#f9f9f9;
	border:1px solid #d8d8d8;
	padding:21px 7.76%;
	.set{
		display:table;
		width:100%;
		>span{
			display:table-cell;
			vertical-align:top;
			padding:0 5px;
		}
		>span>*{
			width:100%;
			height:30px;
		}
		>.select{
			width:150px
		}
		>.keyword{
		}
		>.btn{
			width:100px;
		}
	}
	@include breakpoint($sm){
		.totalsh{
			padding:5px 10px;
		}
		.set>span{
			display:block;
			padding:5px 0;
		}
		.set>.select{
			width:auto;
		}
		.set>.btn{
			display:block;
			width:100%;
		}
	}
}
.tshlist{
	h2{
		overflow:hidden;
		border-bottom:2px solid #444444;
		padding-bottom:10px;
		span{
			float:left;
				font-size: 20px;
				font-family: "NanumSquare";
				font-weight: bold;
				line-height: 1.2;
		}
		a{
			float:right;
				font-size: 14px;
				color: rgb(94, 97, 105);
				font-weight: bold;
				line-height: 1.2;
			padding-left:22px;
			background:url(/static/portal/img/tshbtnmore.png) 0 center no-repeat;
			background-size:16px;

		}
	}
}
.tshpromotion,
.culture{
	ul{
		overflow:hidden;
		margin:0 -10px;
		li{
			float:left;
			width:20%;
			padding:0 10px;
			@include breakpoint($sm){
				float:none;
				width:auto;
				margin-bottom:10px;
			}
			a{
				display:block;
				span{display:block;}
				.img{
					position:relative;
					overflow:hidden;
					&::after {
						content: '';
						display:block;
						padding-top:143.5233160621762%;
					}
					img{width:100%;position:absolute;left:0;top:0;}
				}
				.tit{
					font-size: 18px;
					font-weight: bold;
					line-height: 1.2;
					padding:13px 0 6px;
					}
				.d{
					line-height:1.77;
				}
				.deco{
					line-height:1.77;
				}
				.category{
					font-weight:bold;
				}
			}
		}
	}
}
.tshpromotion{
	ul{
		li{
			width:25%;
			@include breakpoint($sm){
				width:auto;
			}
			a{
				.category{
					font-weight:normal;
					color:$pointcolor;
				}
				.img::after {padding-top:77.95527156549521%;}
			}
		}
	}
}
.fileList,
.webpageList,
.tshNoticeList{
	ul{
		li{
			margin-bottom:20px;
			a{
				display:block;
				@include bu4;
				&::before {
					top:10px;
					width:4px;
					height:4px;
					
				}
				span{}
				.tit{
					line-height:1.2;
					margin-bottom:10px;
					display:block;
					font-size:16px;
					color:#333;
					text-decoration:underline;
					font-weight:bold;
				}
				.deco{
					display:block;
					color:#757575;
					span{
						display:inline-block;
						margin-right:15px;
						&:last-child{
							color:#4b579f;
						}
					}
				}
			}
		}
	}
}

.webpageList{
	ul{
		li{
			a{
				.txt{
					display:block;
				}
				.category{
					color:$pointcolor;
				}
			}
		}
	}
}
.fileList{
	ul{
		li{
			a{
				.deco{
					color:$pointcolor;
					margin-bottom:4px;
				}
				.category{
					color:#757575;
				}
			}
		}
	}
}