.ico-new{display:inline-block;width:15px;height:13px;background:url(/static/commons/img/ico_new.png) 0 0 no-repeat;}
.nodata{
	text-align:center;
	padding:20px 0;
	font-size: 24px;
	font-family: "NanumSquare";
	color: #4b579f;
	line-height: 1.2;
	display:block;
	width:auto;
}
td.nodata{display:table-cell}
img{max-width:100%;}
.m{display:none;}
@include breakpoint($sm){
	.pc{display:none;}
	.m{display:block;
		img{max-width:100%;}
	}
}
a{color:#565656;}
body{
	// font-family:'noto Sans';
	font-family:$fontSet;
	font-size:13px;
	line-height:1.3;
}
p{margin-bottom:5px;}
input[type=text],
input[type=password],
select{
	height:30px;
	vertical-align:middle;
	border:1px solid #d0d0d0;
	border-radius:3px;
	background-color:#fbfbfb;
	text-indent:10px;
}
select{text-indent:0;}
//
/* 필수입력 */
.req{display:inline-block;width:15px;height:15px;}
.req:before {
	content: '';
	font:normal normal normal 10px/1 FontAwesome;content:"\f069";color:red;
	padding-left:5px;
}
.req>*{position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0}
.joinendSet{
	margin-top:16px;
	background:#f0eff2 url(/static/portal/img/HKPU_07_02_bg1.png) right 0 no-repeat;
	padding:55px 0 42px 43px;
	min-height: 314px;
	position:relative;
	@include breakpoint($sm){
		padding:10px;
		background-position:center 0;
		background-size:auto 260px;
	}
	p.t{
		  font-size: 18px;
		  font-family: "NanumSquare";
		  color: rgb(51, 51, 51);
		  font-weight: bold;
		  line-height: 1.2;
		  margin-bottom:10px;
	}
	.deco{
		@include breakpoint($lg){
			position:absolute;
			left:403px;
			top:34px;
		}
		@include breakpoint($sm){
			margin-bottom:75px;
			img{width:180px;}
		}
	}
	.view{
		width:320px;
		@include breakpoint($sm){
			width:auto;
		}
		background-color:#fff;
		>dl>dt{
			width:140px;
			font-weight:normal;
		}
	}
}
// 본인인증
.certify{
	margin-top:30px;
	>div>.s{
		background-color:#eff2f3;
		padding:45px 24px 40px;
		h2{
			  font-size: 24px;
			  font-family: "NanumSquare";
			  color: rgb(75, 87, 159);
			  font-weight: bold;
			  line-height: 1;
			  text-align: center;
			  margin-top:0;
			  margin-bottom:30px;

			&:before {
				content: '';
				display:block;
				height:109px;
				}
			}
		.s{
			min-height:150px;
			p:first-child{margin-bottom:15px;}
			p{margin-bottom:12px;}
			a{float:right;margin-top:-4px;
				@include breakpoint($sm){
					float: none;
					margin-top: 0;
				}

			}
		}
	}
	.ipin h2{
		&:before{
			background:url(/static/portal/img/ipin.png) center 4px no-repeat;
		}
	}
	.phone h2{
		&:before{
			background:url(/static/portal/img/phone.png) center 2px no-repeat;
		}
	}

	.b-blue{display:block;margin:0;}
}

.bu4{
	position:relative;
	padding-left:14px;
	&:before {
		content: '';
		width:3px;
		height:3px;
		background-color:#333333;
		position:absolute;
		left:0;
		top:6px;
	}
}

.rbtn{
	border:1px solid #333333;
	height:26px;
	line-height:24px;
	background-color:#fff;
	font-size:12px;
	display:inline-block;
	padding-left:38px;
	min-width:108px;
	padding-right:14px;
	background:#fff url(/static/portal/img/bu-ico1@3x.png) 11px center no-repeat;
	background-size:16px 16px;
	&.ico1{}
	&.ico2{background-image:url(/static/portal/img/bu-ico2@3x.png);}
}
.defaultsize{
	width:240px;
	@include breakpoint($sm){
		width:100%;
	}
}
.visualtxt1{
	  font-size: 22px;
	  font-family: "NanumSquare";
	em{
		font-weight:bold;
		color:#4b579f;
	}
	margin-bottom:20px;
	@include breakpoint($sm){
		display:none;
	}
	&.type2{
		margin-bottom:0;
	}
	&.type2+.sh{
		margin-top:-22px;
		@include breakpoint($sm){margin-top:0;}
	}
}
.btnSet{
	margin:30px 0;
}
.c0306{
	padding-left:169px;
	background:url(/static/portal/img/HKPU_03_06_img.png) 0 0 no-repeat;
}
.c0307{
	// 제휴사/협찬사
	ul{
		overflow:hidden;
		margin:0 -8px 30px;
		@include breakpoint($sm){
			margin:0 -4px 15px;
		}
		li{
			float:left;
			width:25%;
			padding:8px;
			@include breakpoint($sm){
				width:50%;
				padding:4px;
			}
			&:nth-child(4n+1){clear:left;}
			a{
				border:1px solid #d7d8de;
				display:block;
				text-align:center;
				img{
					vertical-align:top;
					width:100%;
					max-height:80px;
				}
			}
		}
	}
}
.box{
	padding:34px 9px;
	background-color:#f0eff2;
	div{
		position:relative;
		padding:0 110px 0 80px;
		margin: 0 auto;
		max-width:430px;
		@include breakpoint($sm){
			padding-right:90px;
		}
		.title{
			@include bu4;
			position:absolute;
			left:0;
			top:0;
			line-height:30px;
		}
		input{width:100%;}
		button{
			position:absolute;
			right:0;
			top:0;
		}
	}
}
.box2{
	background-color:#f9f9f9;
	border:1px solid #d7d8de;
	padding:12px;
	margin-bottom:19px;
	.num{
		display:inline-block;
		background-color:$pointcolor;
		color:#fff;
		font-weight:bold;
		margin-right:10px;
		text-align:center;
		padding:0 7px;
	}
}
.box3{
	padding:16px;
	border:1px solid #d5d5d5;
	p{text-indent:-10px;padding-left:10px;}
	strong{color:#ff2b2b;}
}
.bu4list{
	p{
		@include bu4;
	}
}
#content{
	>p{
		strong{font-weight:bold;color:$pointcolor;}
	}
}
.numberlist{
	li{
		text-indent:-29px;
		padding-left:29px;
		line-height:1.69;
		&+li{margin-top:14px;}
	}
	em{
		display:inline-block;background-color:$pointcolor;
		color:#fff;
		line-height:1;
		padding:6px 5px;
		text-indent:0;
	}

}

.lpop{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	text-align:center;
	background-color:rgba(0,0,0,0.7);
	height:100%;
	padding:10px;
	display:none;
	&.active{
		display:block;
	}
	.set{
		position:relative;
		top:50%;
		display:inline-block;
		padding:20px;
		margin: 0 auto;
		margin-top:-80px;
		max-width:417px;
		background-color:#fff;
		text-align:left;
		@include breakpoint($sm){

		}
	}
	h1{
		  font-size: 21px;
		  font-family: "NanumSquare";
		  color: rgb(0, 0, 0);
		  padding-bottom:20px;
		  margin-bottom:14px;
		  font-weight: bold;
		  border-bottom:2px solid #000000;
		}
	p{}
	button.lpop-close{
		width:23px;
		height:23px;
		position:absolute;
		right:20px;
		top:20px;
		background:url(/static/portal/img/lpopclose.png) 0 0 no-repeat;
		background-size:23px;
	}
	.btnSet{margin-bottom:0;}
}
.c020103{
	overflow:hidden;
	background:url(/static/portal/img/HKPU_02_01_03_img1.png) left 0 no-repeat;
	background-size:auto 100%;
	margin-bottom:20px;
	@include breakpoint($sm){
		padding-top:56.20689655172414%;
		background-size:100%;
		background-position:right 0;
		margin-bottom:0;
	}
	.right{
		position:relative;
		background-color:#f1f1f1;
		height:326px;
		width:388px;
		float:right;
		padding:80px 34px 0 40px;
		&::before,
		&::after{
			content: '';
			height:51px;
			width:53px;
			position:absolute;
			background:#f1f1f1 url(/static/portal/img/HKPU_02_01_03_img2@3x.png) 0 0 no-repeat;
			background-size:326px;
			left:0;
			top:0;
		}
		&::before{
			background-position:right bottom;
			left: inherit;
			top: inherit;
			bottom:0;
			right:0;
		}
		@include breakpoint($sm){
			width:auto;
			height:auto;
			float:none;
			background-image:none;
			padding-top:47px;
			padding-bottom:47px;
			&::before,
			&::after{
				content: '';
				height:47px;
				width:53px;
				position:absolute;
				background:#f1f1f1 url(/static/portal/img/HKPU_02_01_03_img2@3x.png) 0 0 no-repeat;
				background-size:270px;
				left:0;
				top:0;
			}
			&::before{
				background-position:right bottom;
				left: inherit;
				top: inherit;
				bottom:0;
				right:0;
			}
		}
		h2{
			  font-size: 30px;
			  font-family: "NanumSquare";
			  color: rgb(75, 87, 159);
			  font-weight:normal;
			  margin:0 0 24px 0;
		}
		a{
			margin-left:0;
			margin-top:20px;
			padding:0 30px;

			@include breakpoint($sm){
				padding:0;
				display:block;
			}
		}
	}
}

.c020102{
		position:relative;
		padding:48px 0 61px 0;
		margin-bottom:20px;
		background-color:#f1f1f1;
		text-align:center;
		&::before,
		&::after{
			content: '';
			height:66px;
			width:66px;
			position:absolute;
			background:#f1f1f1 url(/static/portal/img/HKPU_02_01_03_img2@3x.png) 0 0 no-repeat;
			background-size:386px;
			left:0;
			top:0;
		}
		&::before{
			background-position:right bottom;
			left: inherit;
			top: inherit;
			bottom:0;
			right:0;
		}
		@include breakpoint($sm){
			&::before,
			&::after{
				content: '';
				height:47px;
				width:53px;
				position:absolute;
				background:#f1f1f1 url(/static/portal/img/HKPU_02_01_03_img2@3x.png) 0 0 no-repeat;
				background-size:270px;
				left:0;
				top:0;
			}
			&::before{
				background-position:right bottom;
				left: inherit;
				top: inherit;
				bottom:0;
				right:0;
			}
		}
		h2{
			  font-size: 36px;
			  font-family: "NanumSquare";
			  color: rgb(75, 87, 159);
			  font-weight:normal;
			  margin:0 0 31px 0;
		}
		p{margin:0 !important;
			line-height:1.77;
			padding:0 30px;
		}
		a{
			margin-left:0;
			margin-top:35px;
			padding:0 30px;
			@include breakpoint($sm){
				padding:0;
			}
		}
		.leftalign{
			text-align:left;
			padding-left:24px;
		}
}

.c0501{
	background:url(/static/portal/img/HKPU_05_01_bg.jpg) 0 0 no-repeat;
	padding:40px 55px;
	text-align:right;
	@include breakpoint($sm){
		padding:40px 0;
		text-align:center;
	}
	.set{
		background: url(/static/portal/img/HKPU_05_01_decoBg.png) 0 0 no-repeat;
		display:inline-block;
		text-align:center;
		width:270px;
		height:334px;
		color:#fff;
		vertical-align:top;
		.t{
			font-family: "NanumSquare";
			font-size: 36px;
			font-weight: bold;
			span{
				font-family: "NanumGothic";
				font-size:15px;
				display:block;
				line-height:1;
				padding:33px 0 13px;
				font-weight:900;
			}
		}
		.t+p{
			text-align:center;
			line-height:1.86;
			font-size:14px;
		}
	}
}
.c0501_3{
	h2{
		font-size: 30px;
		font-family: "NanumSquare";
		font-weight:normal;
		strong{color:#4b579f;}
		line-height:1.2;
	}
		.title1{
			font-weight:bold;
			color: rgb(51, 51, 51);
			font-weight:normal;
			margin-bottom:15px;
			line-height:1;
			letter-spacing:-1px;
			span{display:inline-block;border-bottom:1px solid #4b579f;
				line-height:1.25;
				padding-top:10px;}
			@include breakpoint($sm){
				span{display:inline;}
			}
			strong{font-weight:600;color:#333;}

		}
		p{margin:24px 0;
			line-height:1.77;
		}
		h2{}
}
// .c0501-2{
// 	.title1{
// 		font-size: 30px;
// 		font-family: "NanumSquare";
// 		color: rgb(51, 51, 51);
// 		font-weight:normal;
// 		margin-bottom:15px;
// 		strong{font-weight:bold;}
// 	}
// 	.title2{margin:30px 0 25px;}
// 	p{
// 		line-height:1.77;
// 	}
// 	h2+p{margin-top:15px;}
// 	.copy{
// 		margin:30px 0 25px;
// 		span{
// 			font-size: 26px;
// 			@include breakpoint($sm){
// 				font-size:20px;
// 			}
// 			font-family: "NanumSquare";
// 			color: rgb(255, 255, 255);
// 			line-height: 1.538;
// 			background-color:#4b579f;
// 			padding:3px;
// 		}
// 	}
// }
.c200075{
	>p{
		@extend .m1;
	}
}
.agg-set{
	font-size:1em !important;
	p{margin-left:0 !important;}
}
.c200023{
	position:relative;
	padding-left:38%;
	@include breakpoint($sm){
		padding:0;
	}
	&::after {
		content: '';
		position:absolute;
		left:0;
		top:0;
		width:33%;
		padding-top:50%;
		background:url(/static/portal/img/HKPU_05_02_bg.jpg) center 0 no-repeat;
		background-size:100%;

		@include breakpoint($sm){
			display:none;
		}
	}
	p{
		line-height:1.77;
		margin:25px 0;
		@include breakpoint($sm){
			margin:15px 0;
		}
		strong{}
	}
	.t{
			font-size: 30px;
			@include breakpoint($sm){
				font-size:18px;
			}
			font-family: "NanumSquare";
			color: rgb(51, 51, 51);
			line-height: 1.5;
			letter-spacing:-1px;

			strong{
				font-weight:normal;
				font-size:36px;
			}
			p{
				border-bottom:1px solid $pointcolor;
				line-height:1.1;
				display:inline;
				margin:0;
			}
		}
}
.star1{
	text-indent:-16px;
	padding-left:16px;
	color:#4b579f;
}
.ico-download2{
	width:16px;
	height:16px;
	display:inline-block;
	background: url(/static/portal/img/ico-download2.png) 0 0 no-repeat;
	background-size:16px;
	@include breakpoint($sm){
		background-image:url(/static/portal/img/ico-download2@3x.png);
	}
}
.downloadlist{
	dt{
		background-color:$viewheadBgcolor;
		border-top:2px solid #4b579f;
		padding:15px 0;
		text-align:center;
		font-weight:bold;
		border-bottom:1px solid #d7d8de;
	}
	dd{
		ul{
			li{
				border-bottom:1px solid #d7d8de;
				padding:7px 0 7px 12px;
				a{display:block;overflow:hidden;
					line-height:27px;
				}
				.t{float:left;
					&+span{float:right;
						i{vertical-align:top;margin-top:4px;}
					}
				}
				.b-mini{
					&::before{
						display:none;
					}
				}

			}
		}
	}
}
@include breakpoint($sm){
	.m-width{width:100%;margin-bottom:5px;}
}
.c200070{
	a{display:block;padding:6px 0 5px;}
}
.c200024{
	.top{
		margin-bottom:58px;
		overflow:hidden;
		.col1{
			width:46%;
			float:left;
		}
		.col2{
			width:54%;
			float:left;
		}
		img{float:left;margin-right:15px;}
		span{display:block;}
		font-size:16px;
		.t{padding:20px 0 0 0;font-size:26px;}
		@include breakpoint($sm){
			margin-bottom:5px;
			.col1,
			.col2{float:none;width:auto;}
			img{float:none;margin:0;margin-top:23px;}
			text-align:center;
			.t{
				padding-top:17px;
			}
		}
	}
	.bottom{
		// text-align:center;
		// img{width:100%;max-width:292px;}
		// @include breakpoint($sm){
		// 	div{margin-top:20px;}
		// }
		.item{
			clear:both;
			overflow:hidden;
			margin-bottom:30px;
			position:relative;
			padding-left:166px;
			@include breakpoint($sm){
				padding:0;
				margin-top:20px;
			}
			&:after{
				content: '';
				position:absolute;
				width:98%;
				height:2px;
				background-color:#aa895b;
				left:0;
				top:31px;
				z-index:1;
				@include breakpoint($sm){
					width:1px;
					height:98%;
					left:50%;
				}
			}

			h2{
				width:129px;
				height:65px;
				line-height:65px;
				color:#fff;
				font-size: 20px;
				font-family: "NanumSquare";
				background-color:#f00;
				text-align:center;
				margin:0;
				border-radius:5px;
				overflow:hidden;
				position:absolute;
				left:0;
				top:0;
				background:url(/static/portal/img/HKPU_05_03_dtbg1.png) 0 0 repeat;
				z-index:2;
				@include breakpoint($sm){
					position:static;
					width:auto;
				}
			}
			ul{
				position:relative;
				z-index:3;
				margin-right:-17px;
				margin-left:-17px;
				overflow:hidden;
				@include breakpoint($sm){
					margin:0;
				}
				li{
					float:left;
					width:33%;
					text-align:center;
					padding:0 17px;
					@include breakpoint($sm){
						float:none;
						margin-top:17px;
						padding:0;
						width:auto;
					}
					&:first-child{width:34%;
						@include breakpoint($sm){width:auto;}}
					>span{
						display:block;
						border:2px solid #aa895b;
						border-radius:5px;
						min-height:65px;
						padding-top:12px;
						background-color:#fff;
						padding-bottom:12px;
					}
				}
			}
			&.item1{
				ul li{
					font-size:24px;
					line-height:65px;
					span{padding-top:0;padding-bottom:0;

					}
				}
			}
			&.item2{
				&:after {
					background-color:#4d94a6;
				}
				h2{background:url(/static/portal/img/HKPU_05_03_dtbg2.png) 0 0 repeat;}
				ul li{
					font-size:14px;
					span{border-color:#4d94a6;}
				}
			}
			&.item3{
				&:after {
					background-color:#79a74b;
				}
				h2{background:url(/static/portal/img/HKPU_05_03_dtbg3.png) 0 0 repeat;}
				ul li{
					font-size:14px;
					span{padding-top:21px;
						border-color:#79a74b;
					}
				}
			}
			&.item4{
				&:after {
					background-color:#4b579f;
				}
				h2{background:url(/static/portal/img/HKPU_05_03_dtbg4.png) 0 0 repeat;}
				ul li{
					text-align:left;
					font-size:14px;
					span{
						padding-left:16px;
						border-color:#4b579f;
					}
				}
			}
		}
	}
}

.c200031{
	position:relative;
	padding-left:309px;
	@include breakpoint($sm){
		padding:0;
	}
	.l{
		width:309px;
		position:absolute;
		left:0;top:0;
		font-size:22px;
		@include breakpoint($sm){
			display:none;
		}
		p{
			font-size: 65px;
			font-family: "NanumSquare";
			color: rgb(75, 87, 159);
			font-weight: 900;
			.c{color:#a1a7cd; }
		}
	}
	.right{
		border-left:2px solid #cccccc;
		padding:13px 0;
		position:relative;
		&::after {
			content: '';
			width:15px;
			height:1px;
			background-color:#ccc;
			position:absolute;
			top:0;left:-9px;
		}
		&::before {
			content: '';
			width:15px;
			height:1px;
			background-color:#ccc;
			position:absolute;
			bottom:0;
			left:-9px;
		}
		dl{
			padding-bottom:20px;
			margin-left:-7px;
		}
		dt{
			background:url(/static/portal/img/history-dt-bg.png) 0 0 no-repeat;
			width:124px;
			height:31px;
		  font-size: 26px;
		  font-family: "NanumSquare";
		  color:#fff;
		  padding-left:45px;
		  line-height:31px;
		  margin-bottom:8px;
			@include breakpoint($sm){
				background:url(/static/portal/img/history-dt-bg@3x.png) 0 0 no-repeat;
				background-size:124px 31px;
			}
		}
		dd{
			padding-left:95px;
			position:relative;
			overflow:hidden;
			.num{
				position:absolute;
				top:0;
				left:45px;
				font-weight:bold;
			}
			.txt{

			}
		}

	}
}
// cibi소개
.c200058{
	img{max-width:100%;}
	.blt3{margin-top:0;}
	.row{margin-top:20px;}
}
.c200059{
	i{display:block;padding-top:20.6%;}
	img{max-width:100%;}
	@include breakpoint($sm){
		.row>div+div{margin-top:15px;}
	}
}
.c200050{
	h2,
	h1{
		background:url(/static/portal/img/0506-bg1.png) 0 0 no-repeat;
		margin: 0 auto 27px;
		width:200px;

		position:relative;
		text-align:center;
		font-size:16px;
		a{
			color:#fff;
			height:70px;
			line-height:70px;
			display:block;
		}
		&::after {
			content: '';
			top:70px;
			left:100px;
			width:1px;
			height:27px;
			background-color:#d1d1d1;
			position:absolute;
		}
		@include breakpoint($sm){
			background:url(/static/portal/img/0506-bg1@3x.png) 0 0 no-repeat;
			background-size:200px;
		}
	}
	h2{
		background-image:url(/static/portal/img/0506-bg2.png);
		height:60px;
		line-height:60px;

		&::after {
			top:60px;
		}
		@include breakpoint($sm){
			background-image:url(/static/portal/img/0506-bg2@3x.png);
		}
	}
	div{
		padding:16px;
		border:1px solid #d1d1d1;
		border-radius:3px;
		background-color:#f8f8f8;
		margin: 0 auto;
		max-width:870px;
		ul{
			overflow:hidden;
			li{
				float:left;
				width:25%;
				padding:0 6px;
				@include breakpoint($sm){
					float:none;
					width:auto;
					&+li{margin-top:12px;}
				}
				span{
					display:block;
					padding:20px 0;
					background-color:#fff;
					border:2px solid #4b579f;
					border-radius:5px;
					text-align:center;
					font-size:16px;
					font-weight:bold;
				  color: rgb(75, 87, 159);
				}
			}
		}
	}
}
#orgList{
	>section{
		display:none;
		&.active{
			display:block;
		}
	}
	th.w1{width:8%}
	th.w2{width:8%}
	th.w3{width:9.69%}
	th.w4{width:12.89%}
	th.w5{width:20.62%;}
}
// 영상정보처리기기
.c200074{
	.h{
		background:url(/static/portal/img/HKPU_09_03_titlebg.png) 0 0 no-repeat;
		padding-left:127px;
		padding-top:20px;
		min-height:114px;
		@include breakpoint($sm){
			background:none;
			padding-left:0;
			padding-top:0;
		}
		h2{
		  font-size: 24px;
		  font-family: "NanumSquare";
		  color: rgb(75, 87, 159);
		  font-weight: bold;
		  line-height:1;
		  margin: 0 0 8px;
		}
		p{
			line-height:1.64;
			margin-top:0 !important;
		}
	}
}
.numtitle{
	@extend .blt1;
	margin-bottom:10px !important;
	em{color:#fff;
		background-color:#4b579f;
		font-weight:bold;
		font-size:13px;
		display:inline-block;
		margin-right:5px;
		line-height:1;
		padding:3px 6px 4px;
		vertical-align:top;
		margin-top:3px;
	}
	&+p,
	&+div{
		margin-left:45px;
		margin-top:0 !important;
		@include breakpoint($sm){
			margin-left:0;
		}
	}
}

//
.c200073{
	.t1{
		strong{
			color:#333;
		}
	}
	.ti{
		em{
			color:#fff;
			background-color:#4b579f;
			font-weight:bold;
			font-size:13px;
			display:inline-block;
			margin-right:5px;
			line-height:1;
			padding:3px 6px 4px;
			vertical-align:middle;
			text-indent:0;
		}
		text-indent:-37px;
		padding-left:37px;
	}
	p{margin:20px 0;
		line-height:1.53;
	}
	strong{
		color:$pointcolor;
	}
	img{float:right;}
	.ml{margin-left:37px;}
	margin-bottom:70px;
}

.c200072{
	.t{font-size:14px;}
	line-height:1.62;
	p{margin:0;}
	>ol{
		>li{
			font-weight:bold;
			text-indent:-20px;
			padding-left:20px;
			>*{
				text-indent:0;
				padding:0;
			}
			>ul{

				margin-left:-20px;
				font-weight:normal;
				margin-bottom:25px;
				>li{}
			}
			.view{text-indent:0;padding:0;
				margin-left:-20px;
				margin-bottom:5px;
			}
		}
	}
	.helpservicecenter{
		margin-top:15px;
		dt{
			font-weight:bold;
		}
		dd{
			font-weight:normal;
		}
	}
}
.fn{font-weight:normal !important}
.c200011{
	margin-top:20px;
	border:1px solid #d7d8de;
	padding:31px;
	img{max-width:100%;}
	>div{
		padding:14px 0;
		overflow:hidden;
		// display:inline-block;
		&+div{
			border-top:1px solid #d7d8de;
		}
		>h2{
			margin:0;
		  font-size: 20px;
		  font-family: "NanumSquare";
		  color: rgb(51, 51, 51);
		  font-weight: bold;
		  line-height: 1.2;
		  float:left;
			width:14%;
			@include breakpoint($sm){width:auto;float:none; }
		}
		>div{
			float:left;
			width:86%;
			@include breakpoint($sm){width:auto;float:none; }
		}
	}
	.m{
		margin:0 -17px 0 -11px;
		overflow:visible;
		display:inline-block;
		>h2{margin-left:11px;}
	}
	.di{
		>*{
			display:inline-block;
			vertical-align:top;
		}
		h3{
			background-color:#4b579f;
			color:#fff;
			font-size:16px;
			padding:3px 5px;
		}
		>div{
			margin:0 10px;
		}
	}
	.map{
		h3{
		  font-size: 20px;
		  font-family: "NanumSquare";
		  color: rgb(51, 51, 51);
		  font-weight: bold;
		  margin:20px 0 10px;
		}
		.num{
			width:20px;
			height:20px;
			display:inline-block;
			text-align:center;
			line-height:20px;
			vertical-align:middle;
			border-radius:20px;
			font-weight:bold;
			color:#fff;
			font-size:15px;

		}
		.p{background-color:#893bb6;}
		.g{background-color:#37b42d;}
		.b{background-color:#003499;}
	}
	.map2{
		padding-top:15px;
		clear:both;
		overflow:hidden;
		width:100%;
		dl{
			width:50%;
			float:left;
			@include breakpoint($sm){width:auto;float:none; }
			margin-top:20px;
			padding-right:0px;
			dt{
			  font-size: 20px;
			  font-family: "NanumSquare";
			  color: rgb(51, 51, 51);
			  font-weight: bold;
				position:relative;
				padding:10px 0 8px;
				&::after {
					content: '';
					position:absolute;
					left:0;top:0;
					width:25px;
					height:2px;
					background-color:#4b579f;
				}
			}
			dd{}
			p{}
			.title{font-weight:bold;
				background-color:#4b579f;
				color:#fff;
				display:inline-block;
				padding:1px 4px;
			}
			em{color:#4b579f;}
		}
		.ti{
			p{
				padding-left:13px;
				text-indent:-13px;
			}
		}
	}
}
.c200046{
	line-height:1.51;
	.title{
		font-size: 30px;
		font-family: "NanumSquare";
		color: rgb(51, 51, 51);
		line-height: 1.2;
		margin:30px 0 15px;
	}
	.deco{
		font-family: "NanumSquare";
		font-size:22px;
		span{
			display:inline-block;
			background-color:#4b579f;
			color:#fff;
		}
	}
	h2{
		margin:35px 0 10px;
		line-height:1;
		&+p{margin-top:0 !important;}
		em{font-weight:bold;}
	}
	p{

		strong{
			font-size: 18px;
			// color: rgb(51, 51, 51);
			color:#4b579f;
			font-weight:normal;
		}
	}

}
.c200048{
	@extend .c200046;
	.title{
		.fz{font-size:40px;}
	}
	.deco{margin:4px 0;}
	dl{
		dt{font-size:16px;font-weight:bold;color:#4b579f;}
		p{margin:0;}
	}
	p{margin:20px 0;
		strong{
			font-size:1em;
			font-weight:bold;
		}
	}
}

.hset{
	margin-top:25px;
	padding:30px 60px 37px 474px;
	position:relative;
	min-height:298px;
	@include breakpoint($sm){
		padding:20px;
		background-image:none !important;
	}
	h2{
		font-size: 30px;
		font-family: "NanumSquare";
		color: rgb(75, 87, 159);
		margin:0 0 25px;
		font-weight:normal;
	}
	p{margin:0 !important;line-height:1.69;}
	a{margin-left:0 !important;margin-top:25px;}
	&.con1{background:#f1f1f1 url(/static/portal/img/HKPU_04_05_con1bg.jpg) 0 0 no-repeat; }
	&.con2{background:#f1f1f1 url(/static/portal/img/HKPU_04_05_con2bg.jpg) 0 0 no-repeat; }
}

@include breakpoint($sm){
	.dbData table{width:100% !important;}
	.dbData table td,
	.dbData table td *{word-break:break-all}
}